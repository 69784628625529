import {createFromInput} from "./html/forms";
import {collectData} from "./invoices";
import {FileMan} from "./fileManager";

export default class AddBidClass {
    filters
    saveData = {subSheetID: 'AddBid'}
    saving = false;

    init(data) {
        console.log('built', data)

        this.filters = ['a','b','c']
        let c = $('#'+CS.currentContextID())
        this.container = $('.modal-body', c)
        this.container.html('')

        this.data = data

        this.initGUI()
    }

    initGUI(){
        let c = $('<div>').addClass('row dataRow')
        let cont = $('<div>').addClass('col-3')

        for(let x1 in this.data.tableData){
            let td = this.data.tableData[x1]
            let m = false
            if(td.title == 'Name'){
                m = (o) => {
                    return o.on('change', () => {
                        this.makeFilters(o.val())
                    })
                }
            }
            cont.append(createFromInput(td,m))
        }

        this.right = $('<div>').addClass('col ')
        this.filterContainer = $('<div>').addClass('')
        this.bidsContainer = $('<div>').addClass('')
        this.right.append(this.filterContainer, this.bidsContainer)

        c.append(cont,this.right)
            this.container.append(c)

        let mapElement = $('<div id="map">')
        cont.append(mapElement)

        let currentSubsheet = CS.getCurrentSubsheet()
        let addr = currentSubsheet.data.value ?? 'Toronto'

        // CS.addToObserveList({
        //     callback: function(){
                CS.Map.loadGoogleMapsApi().then(function(googleMaps) {
                    CS.Map.setGoogleMapsApi(googleMaps)
                    CS.Map.geocode({address: addr},undefined,mapElement[0])
                    // CS.Map.initMap()
                    setTimeout(()=>{
                        $('#address').val(currentSubsheet.data.value ?? '').focus()

                    },500)
                });
            // },
            // element: mapElement[0],
        // })

        // CS.createFromInput([
        //     title: 'Address'
        // ])

        this.updateFilters()
    }


    save(){
        let data = {... this.saveData, ... collectData(this.container)}

        console.log(data)

        if(this.saving){
            CS.showInfo('Already Saving...')
            return
        }
        let n = CS.showInfo('Saving...')

        this.saving = true

        setTimeout(()=>{
            this.saving = false
        },5000)

        // if(CS.getCurrentSubsheet().id) {
        //     data['subSheetID'] = 'AddBid'
        // }else{
        //     data['colID'] = CS.getCurrentSubsheet().colID
        // }

        CS.clearTrackedChanges()

        CS.makeCall({
            url: route('subsheet.save'),
            data: JSON.stringify(data),
            contentType: 'application/json'
        }, (r) => {
            // CS.showSuccess('Saved!')
            // CS.showErrors(out.errors)
            n.hide()
            this.handleResponse(r)
            this.saving = false
        })

    }

    handleResponse(r){

        // CS.handleResponse(r)
        //
        // this.right.html('')
        //
        //
        // if(r.similars.length > 0){
        //
        //     for (let i in r.similars){
        //         let d = r.similars[i]
        //         let c = $('<div>').addClass('row')
        //             .append($('<div>').addClass('col').html(d.ProjectName))
        //             .append($('<div>').addClass('col').html(d.Address))
        //             .append($('<div>').addClass('col').html(d.DueDate))
        //
        //         this.right.append(c)
        //     }
        //
        //     this.right.prepend(
        //         $('<input type="checkbox" name="force">'),
        //         'Add Anyway',
        //         $('<hr>'),
        //
        //     )
        // }

        if(r.newRowID){

            CS.loadSubsheet({
                colID: 2453,//CS.getCurrentSubsheet().colID,
                rowID: r.newRowID,
            })

            // this.right.html('')
            // let fm = new FileMan(this.right)
            //
            // fm.setPath(r.newPath)
            // fm.setRowID(r.newRowID)
            // fm.init()


        }
        else if(r.reload)
            CS.reloadSubsheet()
    }

    addFilter(text){
        this.filters.push(text)

        this.updateFilters()
        this.makeBidList()
    }

    removeFilter(key){
        this.filters.splice(key,1)

        this.updateFilters()
        this.makeBidList()
    }

    updateFilters(){
        this.filterContainer.html('')

        $(this.filters).each((k, f) => {
            let o = $('<div>').addClass('badge bg-secondary btn-group')
            let c = $('<span>').addClass('')
                .html(f)

            let x = $('<button>').addClass('btn').html('X').on('click', () => { this.removeFilter(k) })
            o.append(c,x)
            this.filterContainer.append(o)
        })


    }

    makeFilters(text){
        this.filters = []
        $(text.split(' ')).each((k, t) => {
            this.addFilter(t)
        })

        this.updateFilters()

        this.makeBidList()
    }

    makeBidList(){
        console.log('?.', this.data)
        let res = this.data.bids.filter((obj) => {
            let out = false

            $(this.filters).each((k, v) => {
                for(let x in obj){
                    // console.log('?.', obj[x], obj, x)

                    if((typeof obj[x] === 'string' || obj[x] instanceof String) && obj[x].includes(v)){
                        out = true
                    }
                }

            })

            return out
        })

        console.log('>>.', res)

        let out = $('<div>')

        $(res).each((k, v) => {
            let cols = ['ProjectName', 'Address']
            let row = $('<div>').addClass('row border-bottom-head border-1 border')

            $(cols).each((k2, c) => {
                row.append(
                    $('<div>').addClass('col').html(v[c]),

                )
            })
            row.append(
                $('<button>').addClass('btn btn-sm btn-success col-1').html('Attach').on('click', () => {
                    this.saveData.bidID = v['id']
                    // this.saveData.subSheetID = 'update'
                    this.save()
                })
            )

            out.append(row)
        })

        this.bidsContainer.html(out)

    }

}
