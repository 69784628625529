import {currentContextID, makeCall, reloadSubsheet} from "../calcsys";
import konva from "konva";

export default class DrawingsClass {

    files = []
    data = []
    activePage = 0

    draw = ''
    mode = ''
    pageList = []
    itemList = []
    fileList = []
    canvasContainer = ''
    canvas = ''
    sideContainer = ''
    newMeasure = false

    prevCoord = false
    ctx = false
    container = false
    activeGroup = 0
    canvasSize = []
    img = false

    // imgUID = ''
    //
    // scalePixels = 0
    // scaleBase = 0
    // scale = 1
    // scaleMultiplier = 0.2
    // drawingScale = 18

    mTypes = ['ft','mm','inch']

    constructor() {

    }

    init(data){
        console.log('init Drawing parent', data)

        this.data = data.drawingData || []
        this.files = data.files

        let c = $('#'+CS.currentContextID())
        this.container = $('.modal-body', c)
        this.container.html('')

        this.initGUI()


        return this

    }

    close(){

        this.data = null
        console.log('unload drw obj')

        return null
    }


    initGUI(){

        this.itemList = $('<div>')
        this.fileList = $('<div>')

        let tabs = [
            // {title: 'Home', content: 'upload'},
            // {title: 'tit1', content: content},
        ]

        $(this.data).each((k,d)=>{
            let tabObj = new DrawingPageClass(d, k)
            // // d.obj = tabObj
            let cont = $('<div>')
            tabObj.initTab(cont)
            // tabObj.loadImage(d.i)
            tabs.push({
                title: d.title,
                content: tabObj.makeTabContent(),
                onClick: () => {
                    // console.log('tab click', d, tabObj)
                }
            })

            this.data[k] = tabObj
            // tabObj.initCont  rols()
        })

        let fmc = $('<div>')
        let fm = new CS.FileMan(fmc)
        fm.setPath(CS.getCurrentSubsheet().data.path)
        fm.setRowID(CS.getCurrentSubsheet().rowID)
        fm.setMode('Drawings')


        tabs.push(
            // {title: 'ADD', content: this.homePage()},
            {title: 'Files', content: fmc},
            {title: 'TakeOffs', content: this.takeoffPage()},
            // {title: 'tit1', content: content},
        )

        this.container.append(CS.tabs_html(tabs))

        $(this.data).each((k,d)=>{
            d.initStage()
        })

        fm.init()

    }

    takeoffPage(){
        this.takeoffContainer = $('<div>').addClass('takeoffContainer')
        let bCont = $('<textarea>').addClass('form-control')


        let b = $('<div>').addClass('btn btn-sm btn-primary')
            .html('Get')
            .on('click', ()=>{
                let info = []
                let grandTotal = 0
                $(this.data).each((k,d)=>{

                    console.log('xx', d)
                    $(d.groups).each((k2,g)=>{
                        let x = g.name+': '+g.total.toFixed()+g.measure
                        let t = g.total
                        if(g.subType===2) {
                            x += ' X ' + g.multi + 'ft'
                            t *= g.multi
                        }


                        x += ' @ $'+g.price + ' = $' + CS.formatCurrency((t*g.price).toFixed(2))
                        grandTotal += t*g.price
                        info.push(x)
                    })
                })
                info.push('Grand Total: $' + CS.formatCurrency(grandTotal.toFixed(2)) )
                bCont.html(info.join("\n"))
        })


        this.takeoffContainer.append(b, bCont)

        let out = $('<div>').append(this.takeoffContainer).addClass('row')

        return out
    }


    homePage(){
        this.previewContainer = $('<div>').addClass('col-10 previewContainer')
        this.sideContainer = $('<div>').addClass('col-2 sideContainer')

        this.sideContainer.append(this.itemList, this.fileList)
        let cs = CS.getCurrentSubsheet()

        this.previewContainer.append(CS.fileUploadForm_html(
            [
                {name: 'subSheetID', value: cs.id },
                // {title: 'Number of Pages', name: 'pages', value: 0 , type: 'number'},
                // {title: 'Starting From', name: 'offset', value: 0 , type: 'number'},
                // {name: 'colID', value: cs.colID},
            ],
            route('Drawings.uploadPDF')))

        let out = $('<div>').append(this.previewContainer, this.sideContainer).addClass('row')
        this.renderPageList()
        this.renderFileList()

        return out
    }

    setScale(p,b,s){
        $(this.data).each((k,d)=>{
            d.scalePixels = p
            d.scaleBase = b
            d.drawingScale = s
        })
    }

    save(){
        let saveData = []

        $(this.data).each((k, o) => {
            if(o.drawingScale){
                let x = {...o}
                x._drawLayer = false
                x._backgroundLayer = false
                x._newLayer = false
                x.stage = false
                saveData.push(x)
            }
            // {
            //         drawingScale: o.drawingScale,
            //         imgUID: o.imgUID,
            //         page: o.page,
            //         scale: o.scale,
            //         scaleBase: o.scaleBase,
            //         scaleMultiplier: o.scaleMultiplier,
            //         scalePixels: o.scalePixels,
            //         title: o.title,
            //     })
        })

        let cs = CS.getCurrentSubsheet()

        // return saveData
        CS.ajaxCall({
            url: route('subsheet.save'),
            data: JSON.stringify({data: saveData, colID: cs.colID, subSheetID: cs.id}),
            contentType: 'application/json',
            dataType: 'json',
            processData: true,
        }, function (data) {

            CS.handleResponse(data)
        });
    }



    handleResponse(out){
        if(out.picklist){
            this.renderPicklist(out)
        }

        if(out.reload){
            CS.reloadSubsheet()
        }

    }



    addPage(num, uid){
        this.data.push(new DrawingPageClass({
            title: 'drawing',
            page: num,
            imgUID: uid
        }))
    }

    renderPageList(){
        this.itemList.html('')

        $(this.data).each((k,page)=> {
            let cont = $('<div>')
                .addClass('pageContainer row')



            let title = $('<div>')
                .addClass('col')

            let name = $('<div>')
                .addClass('')
                .html(page.title)



            title.append(name)

            let rename = $('<span>')
                .html('R')
                .addClass('btn btn-sm btn-dark col-1')
                .on('click', () => {
                    console.log('rename', name)
                    let inp = $('<input>')
                        .val(page.title)
                        .on('change', () => {

                            page.title = inp.val()
                            this.renderPageList()
                        })

                    $(name).html(inp)

                    inp.focus()

                })




            let del = $('<div>')
                .html('X')
                .addClass('btn btn-sm  btn-danger col-1')
                .on('click', ()=> {
                    this.removePage(k)
                    // this.renderPageList()
                })

            cont.append(title,rename,del)
            this.itemList.append(cont)
        })


        let save = $('<div>').addClass('btn btn-success').html('Save Changes')
            .on('click', () => { this.commitPages() })
        this.itemList.append(save)

        return this.itemList

    }
    renderFileList(){
        this.fileList.html('<hr>')

        $(this.files).each((k,file)=> {
            let cont = $('<div>')
                .addClass('btn-sm btn btn-success m-1')
                .html(file.name)
                .on('click', () => {
                    this.renderPicklist(file)
                })


            this.fileList.append(cont)
        })


    }

    commitPages(){
        let cs = CS.getCurrentSubsheet()
        let d = {
            data:  this.data,
            subSheetID: cs.id,
            update: 'pages',
            // uid: this.currentPage().imgUID
        }
        // return saveData
        CS.ajaxCall({
            url: route('subsheet.save'),
            data: JSON.stringify(d),
            contentType: 'application/json',
            dataType: 'json',
            processData: true,
        }, function (data) {
            // CS.Drawings.newImagesList = []
            CS.handleResponse(data)

            CS.reloadSubsheet()
        });
    }

    removePage(num){
        let out = []
        $(this.pageList).each((k, v) => {
            if(num !== k)
                out.push(v)
        })

        this.pageList = out
    }




}


class DrawingPageClass {
    scalePixels = 0
    scaleBase = 0
    drawingScale = 18
    scale = 1
    scaleMultiplier = 0.2
    scaleContainer = false
    imgUID = false
    groups = []
    newMeasure = false
    prevCoord = false
    canvasSize = [10,10]

    keyNoteSrc

    stage = false
    _drawLayer = false
    _backgroundLayer = false
    _backgroundImage = false
    _newLayer = false

    _isPinching = false


    constructor(data, pn) {
        this.pageNum = pn
        // this.data = data
        for(let x in data){
            this[x] = data[x]
        }


        this.keyNoteSrc = route('Drawings.getKeyNote', {id: this.imgUID, page: this.page})



    }

    events(){
// console.log('events!')
        this.stage.on('click', (evt) => {
            let e = evt.evt
            let coord = { x: e.offsetX/this.scale, y: e.offsetY/this.scale}
            console.log('clicked', e, coord, this.mode, this.draw,this.prevCoord)
            if(!this.mode)// === '')
                return

            if(this.draw === 'count'){
                this.group().items.push(coord)
                this.renderItemList()

                this.drawCanvas()

                this.resetMeasures()

                return
            }
            if(this.draw === 'area')
            {
                // if(!this.newMeasure){
                //     this.newMeasure = {
                //         points: [coord],
                //         type: 'area'
                //     }
                // }
                // else
                if(this.checkCoordProx(coord,this.newMeasure.points[0])){
                    this.groups[this.activeGroup].items.push(this.newMeasure)
                    this.renderItemList()

                    this.drawCanvas()

                    this.resetMeasures()

                    return
                }

            }
            if(this.draw === 'scale')
            {
                if(this.prevCoord){

                    this.mode = ''
                    let base = this.scaleBase //width of a door
                    if(!base) {
                        alert('Set Base and try again')
                        this.prevCoord = false
                        return;
                    }
                    let pixels = this.distanceBetween(coord, this.prevCoord)

                    let ss = $('select', this.scaleContainer).val()
                    let all = $('.setAll', this.scaleContainer).attr('checked')

                    if(ss === 'mm'){
                        base /= 304.8
                    }

                    console.log('Setting drawing scale', base, pixels, ss,all)
                    this.scalePixels = pixels
                    this.drawingScale = pixels / base
                    this.prevCoord = false

                    this.renderItemList()
                    // this.renderScale()
                    this.scaleContainer.html('')
                    $('.drawButtons', this.sideContainer).show()


                }else
                    this.prevCoord = coord
                return
            }

            if(this.draw === 'path')
            {

                if(this.checkCoordProx(coord)){
                    console.log('clicked next to prev point' )

                    this.groups[this.activeGroup].items.push(this.newMeasure)
                    this.renderItemList()

                    this.drawCanvas()

                    this.prevCoord = false
                    this.mode = 'start'
                    this.newMeasure = {
                        type: this.group().type,
                        points: [],

                    }
                    this._newLayer.destroyChildren()


                    return
                }

                this.prevCoord = coord


            }

            this.addPoint(coord)
            // this.ctx.stroke()
            //
            // if(this.mode === 'start'){
            //     this.ctx.fillStyle = "rgba(200, 0, 0, 0.5)"
            //     this.ctx.fillRect(coord.x - 2.5, coord.y - 2.5, 5, 5)
            //     this.mode = 'join'
            //     this.ctx.beginPath()
            //     this.ctx.moveTo(coord.x, coord.y)
            // }
            //
            // if(this.mode === 'join'){
            //     this.ctx.lineTo(coord.x, coord.y)
            //     this.ctx.stroke()
            // }
            //

        })
    }

    setActiveGroup(k){
        this.activeGroup = k
        this.draw = this.group().type
        this.renderItemList()
        this.resetMeasures()
    }

    resetMeasures(){
        this.prevCoord = false
        this.mode = 'start'
        this.newMeasure = {
            type: this.group().type,
            points: [],

        }

        this._newLayer.destroyChildren()
    }

    renderItemList(){
        this.itemList.html('')

        $(this.groups).each((k,group)=> {
            let cont = $('<div>')
                .addClass('groupContainer row')
            if(k === this.activeGroup)
                cont.addClass('active')


            let vis = $('<input type="checkbox">')
                .addClass('form-check-input')
                .attr('checked', group.visible)
                .on('click', () => {
                    group.visible = vis.prop('checked')
                    this.drawCanvas()
                })
            let total = 0
            let itemList = $('<div>').addClass('groupItemList col-12 ')
// console.log('zzz', item)
            let pcont = $('<div>').addClass(' priceContainer')
            let price = $('<input>').val(group.price)
                .on('change', () => {
                    group.price = price.val()
                })
            let multi = $('<input>').val(group.multi)
                .on('change', () => {
                    group.multi = multi.val()
                })

            let grp = []

            if(group.subType === 2)
                grp.push(
                    {text:'X'},
                    {input: multi},
                    {text: group.measure}
                )

            grp.push(
                {text:'@'},
                {text:'$'},
                {input: price}
            )
                // pcont.append( 'X', multi)

            // pcont.append( '@ $', price)

            pcont.append(CS.inputGroup(grp))
            itemList.append(pcont)

            $(group.items).each((num, val)=>{



                let cont = $('<div>').addClass('row itemListContainer')
                let len = -1
                let title = ''
                if(group.type === 'path'){
                    len = this.itemLength(val)
                    title = this.pixelsToFeet(len)+' ft' //('+len+'px)'
                }
                if(group.type === 'area'){
                    len = this.itemArea(val)
                    title = this.pixelsToSqFeet(len)+' sf'// ('+len+'px)'

                }
                let i = $('<span>')
                    .html(title)
                    .addClass('col')


                let del = $('<span>')
                    .html('X')
                    .addClass('btn btn-sm btn-danger d-inline col-1')
                    .on('click', () => {
                        this.removeItem(num)
                        this.renderItemList()
                        this.drawCanvas()
                    })

                cont.append(i,del)
                itemList.append(cont)

                total += len

            })


            let title = $('<div>')
                .addClass('col')

            let name = $('<div>')
                .addClass('')
                .html(group.name)
                .on('click', () => {
                    this.setActiveGroup(k)
                })

            let msr = ' ft'
            let totalOut = 0
            if(group.type === 'area'){
                totalOut = this.pixelsToSqFeet(total)
                msr = ' sf'
            }
            if(group.type === 'path'){
                totalOut = this.pixelsToFeet(total)
            }
            if(group.type === 'count'){
                totalOut = group.items.length
                msr = ' pieces'
            }

            group.total = totalOut
            group.measure = msr


            let totalOutput = $('<div>')
                .addClass('badge bg-secondary')
                .html(totalOut + msr)

            title.append(name,totalOutput)

            // let rename = $('<span>')
            //     .html('R')
            //     .addClass('btn btn-sm btn-dark col-1')
            //     .on('click', () => {
            //         console.log('rename', name)
            //         let inp = $('<input>')
            //             .val(group.name)
            //             .on('change', () => {
            //
            //                 group.name = inp.val()
            //                 this.renderItemList()
            //             })
            //
            //         $(name).html(inp)
            //
            //         inp.focus()
            //
            //         name.on('click', () => {})
            //     })


            let color = $('<input>')
                .val(group.color)
                .addClass('')
                .css('width', '30px')
                .attr('type', 'color')
                .on('change', () => {
                    group.color = color.val()
                    this.drawCanvas()
                })
            // let del = $('<div>')
            //     .html('X')
            //     .addClass('btn btn-sm  btn-danger col-1')
            //     .on('click', () => {
            //         this.removeGroup(k)
            //         this.renderItemList()
            //         this.drawCanvas()
            //     })
            let mc = $('<div>').addClass('col-2 align-content-end')

            let m = new CS.Dropdown('<i class="fa-solid fa-ellipsis-vertical"></i>')
            let funcRename = () => {
                console.log('rename', name)
                let inp = $('<input>')
                    .val(group.name)
                    .on('change', () => {

                        group.name = inp.val()
                        this.renderItemList()
                    })

                $(name).html(inp)

                inp.focus()

                name.on('click', () => {})
            }
            m.add('Rename',funcRename)
            m.add('Delete',() => {
                this.removeGroup(k)
                this.renderItemList()
                this.drawCanvas()
            })

            mc.html(m.out()).prepend(color)

            cont.append(vis,title,mc, itemList)


            this.itemList.append(cont)

            if(group.name === ''){
                funcRename()
            }
        })
    }

    startDrawing(){
        this.resetMeasures()
        this.draw = this.group().type
        this.mode = 'start'
    }

    initControls(){
        let add = new CS.Dropdown('Add')

        add.add('GDW Walls', () => {	//// Distance groups
            this.newGroup({
                type: 'path', // path = measure distance
                price: 1,
                subType: 2, // if its a wall with length and height
                multi: 9, // default height
                color: '#ff0000',
                name: 'GDW Walls'
            })
        })
        add.add('Block Walls', () => {
            this.newGroup({
                type: 'path',
                price: 5,
                subType: 2,
                multi: 9,
                name: 'Block Walls'
            })
        })
        add.add('Millwork', () => {
            this.newGroup({
                type: 'path',
                price: 10,
                multi: 9,
                name: 'Millwork'
            })
        })
        add.add('Bulkhead', () => {
            this.newGroup({
                type: 'path',
                price: 0,
                multi: 9,
                name: 'Bulkhead'
            })
        })

		add.add('Vanities', () => {
            this.newGroup({
                type: 'count',
                price:50,
                name: 	 'Vanities'
            })
        })
		add.add('WR Dividers', () => {
            this.newGroup({
                type: 'count',
                price: 75	,
                name: 	 'WR Dividers'
            })
        })
		add.add('Doors', () => {
            this.newGroup({
                type: 'count',
                price: 50,
                name: 	 'Doors'
            })
        })
        add.add('VCT Floor', () => {				//area groups
            this.newGroup({
                type: 'area',
                price: 0.6,
                name: 'VCT Floor'
            })
        })
		add.add('Ceramic Tile Floor', () => {
            this.newGroup({
                type: 'area',
                price: 1.5,
                name: 'Tile Floor'
            })
        })
		add.add('Carpet', () => {
            this.newGroup({
                type: 'area',
                price: 0.6,
                name: 'Carpet'
            })
        })
		add.add('ACT Ceiling', () => {
            this.newGroup({
                type: 'area',
                price: 0.6,
                name: 'ACT Ceiling'
            })
        })
		add.add('GDW Ceiling', () => {
            this.newGroup({
                type: 'area',
                price: 1.5,
                name: 'GDW Ceiling'
            })
        })
        add.add('Area', () => {
            this.newGroup({
                type: 'area'
            }) // area = measure area
        })
        add.add('Distance', () => {
            this.newGroup({
                type: 'path'
            })
        })
        add.add('Items', () => {			//Item groups
            this.newGroup({
                type: 'count',
                price: 1,
                name: 	 ''
            })
        })

        // SHAYNE: End here

        let rev = $('<div>').addClass('btn btn-sm btn-info').html('Undo').on('click', () => {
            this.newMeasure.points.pop()
            this.drawNewMeasures()
        })
        let keyNotes = $('<div>').addClass('btn btn-sm btn-info').html('Notes').on('click', () => {
            CS.startPhotoEditor(this.img, 1, (i) => {
                makeCall({
                    url: route('Drawings.setKeyImage'),
                    data: {img: i, id: this.imgUID, page: this.page},
                }, (r) => {
                    this.keyNoteSrc = i
                    // origImg.src = dataURL
                })
            })
        })
        // let draw = $('<div>').addClass('btn btn-sm btn-success').html('Draw').on('click', () => {
        //     this.startDrawing()
        // })


        let scaleClick = () => {
            this.draw = 'scale'
            this.mode = 'start'
            this.prevCoord = false
            // this.ctx.beginPath()
            this.renderScale()

        }

        let adv = new CS.Dropdown('Opts')
        adv.add('Scale', scaleClick)
        adv.add('Download',  () => {
            window.location.href = route('Drawings.getPDFPage', [this.imgUID,this.page])

        })
        // let zoomIn = $('<div>').addClass('btn btn-sm btn-info').html('Z-In').on('click', () => {
        //     this.scale += this.scaleMultiplier
        //     this.drawCanvas()
        // })
        // let zoomOut = $('<div>').addClass('btn btn-sm btn-info').html('Z-Out').on('click', () => {
        //     this.scale -= this.scaleMultiplier
        //     this.drawCanvas()
        // })

        this.scaleContainer = $('<div>').addClass('scaleContainer')

        let l = $('<div>').append(add.out(),rev,keyNotes,adv.out()).addClass('drawButtons')
        let c = $('<div>').append(l,this.scaleContainer)
        this.sideContainer.prepend(c)
        if(!this.scaleBase){
            // $(scale.trigger('click'))
            scaleClick()
            $('.drawButtons', this.sideContainer).hide()
        }else{

        }

            // newAreaGroup, newDistanceGroup,




    }

    renderScale(){
        let out = $('<div>')
        let base = $('<input>').val(this.scaleBase).on('change', () => { this.scaleBase = base.val() })
        let baseLabel = $('<div>').html('Enter base measurement then click 2 points to scale drawing').addClass('badge badge-sm bg-secondary ')
        let pixels = $('<input>').val(this.scalePixels).on('change', () => { this.scalePixels = pixels.val() })
        let pixelsLabel = $('<label>').html('Pixels').addClass('badge badge-sm bg-secondary ')

        let scale = $('<input>').val(this.scale)
        let scaleLabel = $('<label>').html('Scale')
        let scaleMultiplier = $('<input>').val(this.scaleMultiplier)
        let scaleMultiplierLabel = $('<label>').html('Scale Multiplier')
        let drawingScale = $('<input>').val(this.drawingScale).on('change', () => { this.drawingScale = drawingScale.val() })
        let drawingScaleLabel = $('<label>').html('drawingScale')

        let all = $('<div>').append(
            $('<input>').attr('type', 'checkbox').addClass('setAll'),
            'Set for all Pages'
        )
        let ms = CS.htmlSelect({
            opts: [
                {name: 'Feet', value: 'ft'},
                {name: 'Millimeters', value: 'mm'},
                // {name: 'Inch', value: 'in'},
                // {name: 'Meters', value: 'm'},
            ]
        })

        out.append(
            $('<div>').append(baseLabel, base, ms,all),
            // pixelsLabel,
            // pixels,
            // scaleLabel, scale,
            // scaleMultiplierLabel, scaleMultiplier,
            // drawingScaleLabel, drawingScale,
        )

        this.scaleContainer.html(out)
        return out
    }

    makeTabContent(){
        let content = $('<div>')
            .addClass('container-fluid DrawingsContainer')

        let row = $('<div>').addClass('row')
        let x = $(`<span class="keyNotesButton" tabindex="0" data-bs-trigger="hover focus" >
  Key Notes
</span>`)

        new Popover(x, {
            content: () => {
                return "<img src='"+this.keyNoteSrc+"' alt='None found...' class='keynoteImg'/>"
            },
            placement: 'left',
            html: true,
            container: row,
        })
        row.append(x,this.canvasContainer,this.sideContainer)

        content.append(row)


        return content
    }

    newGroup(opts){
        let group = {... {
            name: '',
            color: '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0'),
            items: [],
            visible: true,
            type: '',
            subType: 1,
            price: 0,
            multi: 1
        }, ...opts}

        this.groups.push(group)
        this.setActiveGroup(this.groups.length - 1)

        this.renderItemList()

        this.startDrawing()
    }

    pixelsToFeet(v){
        return Math.round((v / this.drawingScale)*10)/10
    }
    pixelsToSqFeet(v){
        let a = Math.sqrt(v)
        let b = this.pixelsToFeet(a)
        let c = b * b
        return Math.round(c*10)/10
    }

    areaFromCoords(coordArray) {
        var x = coordArray,
            a = 0;

        // Must have even number of elements
        if (x.length % 2) return;

        // Process pairs, increment by 2 and stop at length - 2
        for (var i=0, iLen=x.length-2; i<iLen; i+=2) {
            a += x[i]*x[i+3] - x[i+2]*x[i+1];
        }
        return Math.abs(a/2);
    }

    removeItem(num){
        let out = []
        $(this.group().items).each((k, v) => {
            if(num !== k)
                out.push(v)
        })

        this.group().items = out
    }
    removeGroup(num){
        let out = []
        $(this.groups).each((k, v) => {
            if(num !== k)
                out.push(v)
        })

        this.groups = out
    }

    group(){
        return this.groups[this.activeGroup]
    }

    checkCoordProx(coord, pc){
        if(!pc) pc = this.prevCoord

        let x = coord.x-pc.x;
        let y = coord.y-pc.y;
        let boundry = 10

        if(
            x > boundry*-1 && x < boundry
            &&
            y > boundry*-1 && y < boundry

        ){
            return true
        }

        return false
    }

    distanceBetween(p1,p2){
        var a = p1.x - p2.x
        var b = p1.y - p2.y

        return Math.sqrt( a*a + b*b )
    }

    itemArea(item) {
        let out = 0
        let w = false
        let coords = [];
        let first = [];
        $(item.points).each((k,v)=>{
            coords.push(v.x, v.y)

            if(k<1){
                first = [v.x, v.y]
            }else{

                let xDiff = v.x - w.x
                let yDiff = v.y - w.y
                // if(xDiff < 0) xDiff *= -1
                // if(yDiff < 0) yDiff *= -1
                let add = (w.x * yDiff - w.y * xDiff)
                if(add < 0) add *= -1
                out += add

            }
            w = v
        })

        coords.push(first[0], first[1])


        return this.areaFromCoords(coords) //out
    }

    itemLength(item) {
        let out = 0
        let prevPoint = false
        $(item.points).each((k,v)=>{
            if(k<1){

            }else{
                out += this.distanceBetween(prevPoint, v)
            }
            prevPoint = v
        })

        return Math.round(out*10)/10
    }

    drawLayer(){ return konva.Node.create(this._drawLayer) }
    backgroundLayer(){ return konva.Node.create(this._backgroundLayer) }
    newLayer(){ return konva.Node.create(this._newLayer) }

    initStage(){
        this.stage = new konva.Stage({
            // width: this.canvasSize[0]*this.scale,
            // height: this.canvasSize[1]*this.scale,
            container: 'canvas'+this.pageNum
        })


        this.stage.addEventListener('wheel', (e) => {

            if(e.ctrlKey){

                e.preventDefault()

                let sx = this.stage.scale().x - e.deltaY/500
                if((this.scale - e.deltaY/500) < 0.2) return false
                if((this.scale - e.deltaY/500) > 2) return false

                this.stage.scaleY(this.stage.scale().y - e.deltaY/500)

                this.stage.scaleX(sx)
                this.scale -= e.deltaY/500
                if(this._isPinching)
                    clearTimeout(this._isPinching)

                this._isPinching = setTimeout(()=>{
                    this.stage.scale({x: 1, y: 1})
                    this.drawCanvas()
                    this._isPinching = false
                }, 100)
            }
        }, {passive: false})

        this._drawLayer = new konva.Layer();
        this._backgroundLayer = new konva.Layer();
        this._newLayer = new konva.Layer();

        this.stage.add(this._backgroundLayer)
        this.stage.add(this._drawLayer)
        this.stage.add(this._newLayer)

        var imageObj = new Image()
        imageObj.onload =  () => {
            this.canvasSize = [imageObj.width, imageObj.height]

            this._backgroundImage = new Konva.Image({
                // x: 50,
                // y: 50,
                image: imageObj,
                width: this.canvasSize[0]*this.scale,
                height: this.canvasSize[1]*this.scale,
            });

            // add the shape to the layer
            this._backgroundLayer.add(this._backgroundImage);
            console.log('img loaded...',this._backgroundLayer)
            this.drawCanvas()

        }
        imageObj.src = route('Drawings.loadImg', [this.imgUID,this.page])
        this.img = imageObj

        this.events()


        this.drawCanvas()
    }

    initTab(container){
        this.canvasContainer = $('<div>').addClass('col-10 canvasContainer').attr('id','canvas'+this.pageNum)
            // .append($('<canvas>').attr('id','canvas'+this.page))
        this.sideContainer = $('<div>').addClass('col-2 sideContainer')
        this.itemList = $('<div>')
        this.fileList = $('<div>')
        this.sideContainer.append(this.itemList, this.fileList)


        this.container = container
        this.container.html('')




        // this.initGUI()
        this.initControls()
        // if(this.groups.length === 0)
        //     this.newGroup('path')

        this.renderItemList()
        // this.loadImage()

        // this.drawCanvas()
        // this.newGroup('path')
    }


    loadImage(){

        if(this._backgroundImage.size)
            this._backgroundImage.size({
                width: this.canvasSize[0]*this.scale,
                height: this.canvasSize[1]*this.scale,
            });

        this.stage.width(this.canvasSize[0]*this.scale)
        this.stage.height(this.canvasSize[1]*this.scale)


        //
        // konva.Image.fromURL(route('Drawings.loadImg', [this.imgUID,this.page]),  (darthNode) => {
        //     darthNode.setAttrs({
        //         // x: 200,
        //         // y: 50,
        //         scaleX: this.scale,
        //         scaleY: this.scale,
        //         cornerRadius: 20,
        //     });
        //     this.backgroundLayer.add(darthNode);
        //
        //     this.canvasSize = [darthNode.attrs.image.width, darthNode.attrs.image.height]
        //

        // });
    }



    drawItems(){
        $(this.groups).each((itemNum,group) => {
            if(group.visible === false) return
            $(group.items).each((n,i) => {
                i.color = group.color
                if(group.type === 'path')
                    this.drawLines(i)
                if(group.type === 'area')
                    this.drawAreas(i)
                if(group.type === 'count')
                    this.drawCounts(i)
            })
        })

    }
    drawCounts(item){


        let star = new Konva.Star({
            x: item.x*this.scale,
            y: item.y*this.scale,
            numPoints: 5,
            innerRadius: 10*this.scale,
            outerRadius: 15*this.scale,
            fill: item.color,
            stroke: item.color,
            strokeWidth: 2*this.scale
        });

        this._drawLayer.add(star)
    }
    drawLines(item){


        // this.ctx.beginPath()

        // this.ctx.strokeStyle = item.color
        let coords = [];
        $(item.points).each((k, v) => {
            coords.push(v.x*this.scale, v.y*this.scale)
            // if(k < 1) this.ctx.moveTo(v.x, v.y)
            // else this.ctx.lineTo(v.x,v.y)
        })

        // this.ctx.stroke()
        let line = new Konva.Line({
            points: coords,
            stroke: item.color,
            strokeWidth: 5,
            lineCap: 'round',
            lineJoin: 'round',
            opacity: 0.4

        });

        this._drawLayer.add(line)
    }
    drawAreas(item){
        // this.ctx.beginPath()
        //
        // this.ctx.fillStyle = item.color
// console.log('-->',item)
        let coords = [];
        $(item.points).each((k, v) => {
            coords.push(v.x*this.scale, v.y*this.scale)
            // if(k < 1) this.ctx.moveTo(v.x, v.y)
            // else this.ctx.lineTo(v.x,v.y)
        })
        var poly = new Konva.Line({
            points: coords,
            fill: item.color,
            stroke: 'black',
            strokeWidth: 3,
            closed: true,
            opacity: 0.4
        });
        this._drawLayer.add(poly)
        // this.ctx.lineTo(item.points[0].x,item.points[0].y)
        //
        // this.ctx.fill()
    }

    clearCanvas(){
        this._drawLayer.destroyChildren()
        // this._backgroundLayer.destroyChildren()

    }

    drawCanvas(){
        // console.log('drawing', this)
        this.clearCanvas()
        this.loadImage()
        this.drawItems()
        this.drawNewMeasures()
    }

    drawNewMeasures(){
        this._newLayer.destroyChildren()

        let c = [];
        $(this.newMeasure.points).each((k, v) => {
            c.push(v.x * this.scale, v.y * this.scale)

            var ring = new konva.Ring({
                innerRadius: 4,
                outerRadius: 8,
                fill: 'red',
                stroke: 'black',
                strokeWidth: 1,
                x: v.x * this.scale,
                y: v.y * this.scale
            })
            this._newLayer.add(ring)

        })
        var line = new konva.Line({
            points: c,
            stroke: 'red',
            strokeWidth: 5,
            lineCap: 'round',
            lineJoin: 'round',
        })

        this._newLayer.add(line)
    }

    addPoint(coord){
        console.log('Add Point', coord,this.newMeasure.points)

        this.newMeasure.points.push(coord)

        this.drawNewMeasures()

        // this.ctx.strokeStyle = 'red'
        // if(this.mode === 'start') this.ctx.moveTo(coord.x, coord.y)
        // else this.ctx.lineTo(coord.x,coord.y)

        this.mode = 'drawing';

    }

}


