import './calcsys/common'
import './calcsys/general'

export *  from './calcsys/common'
export *  from './calcsys/html'
export *  from './calcsys/html/spinner'
export *  from './calcsys/filter'
export *  from './calcsys/sheet'
export *  from './calcsys/changeLog'
export *  from './calcsys/changeTracker'
export *  from './calcsys/invoices'
export *  from './calcsys/subsheets'
export * from './calcsys/notifs'
export * from './calcsys/fileManager'
export * from './calcsys/photoEditor'
export * from './calcsys/map'

let indexes = []











let CurrentUser = false
let Admin = false
let SuperAdmin = false
let Manager = false
let currentCellID = false
export let googleMaps = false


import DrawingsClass from './calcsys/drawings'
export let Drawings = new DrawingsClass()

import TimesheetClass from './calcsys/timesheet'
export const Timesheet = new TimesheetClass()

import UserClass from './calcsys/User'
export const User = new UserClass()

import SheetClass from './calcsys/sheet'
export const Sheet = new SheetClass()

import EmailClass from './calcsys/emails'
export const Email = new EmailClass()

import AddBidClass from './calcsys/addBid'
export const AddBid = new AddBidClass()




export const dpOptions = {
    dateFormat:'d-M-yy',
    // position: 'c',
    formatter: (input, date, instance) => {
        input.value = dateFormater(date)
    },
    // calendarContainer: $('body').first()
};

export function currentUser(set = -1){
    if(set === -1) return CurrentUser;
    CurrentUser = set;
}
export function isAdmin(set = -1){
    if(set === -1) return Admin;
    Admin = set;
}
export function isSuperAdmin(set = -1){
    if(set === -1) return SuperAdmin;
    SuperAdmin = set;
}
export function isManager(set = -1){
    if(set === -1) return Manager;
    Manager = set;
}

export function setIndexes(name, index){
    console.log('setInd',name ,index)
    if(index)
        indexes[name] = index
}
export function getIndex(index){ return index ? indexes[index] : indexes }
export function pushToIndex(index, key, val){ indexes[index][key] = val }




export function makeCall(opts, callBack, notifications = true)
{
    ajaxCall(opts, callBack, notifications)
}

export function handleFormSubmit(event){
    console.log('ajax hit',this)
    event.preventDefault();

    CS.ajaxCall({
            url: this.action,
            data: $(this).serializeArray(),
        },
        handleResponse)

}

export function ajaxCall(opts, callBack = (x)=>{}){
    console.log('Start Ajax-Call', opts)
    if(!opts.url) {
        CS.showError('No URL passed')
        return
    }
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': jQuery('meta[name="csrf-token"]').attr('content')
        }
    });

    const defaultOpts = {
        type: "POST",
        url: '/',
        cache: false,
        // contentType: 'text/json',
        // dataType: 'json',
        // processData: true,
        data: [],
        success: function (data) {
            if(opts.callback) opts.callback(data)

            callBack(data);

        },
        error: onErrorResponse
    }
    const newOpts = {... defaultOpts,... opts}
    $.ajax(newOpts);
}



function onErrorResponse(data){
    if(data.status === 419)
    {
        return CS.expiredSessionDialog()
    }
console.log(data)
    CS.showError("OOPS! Something went wrong: Please refresh and try again.<br>");
    CS.showError(data.responseJSON.message+" L:"+data.responseJSON.line);
}

//todo move this to xsheet
export function dateFormater(d) {

    var options = {day: 'numeric', month: 'short', year: 'numeric'};
    const o_date = new Intl.DateTimeFormat('en-US', options);//.format(d);
    const f_date = (m_ca, m_it) => Object({...m_ca, [m_it.type]: m_it.value});
    const m_date = o_date.formatToParts(d).reduce(f_date, {});
    return m_date.day + '-' + m_date.month + '-' + m_date.year;

    let month = d.getMonth() + 1;
    let date = d.getDate();
    if (month < 10) month = `0${month}`;
    if (date < 10) date = `0${date}`;
    return `${d.getFullYear()}-${month}-${date}`;
}



export function getDate( element ) {
    var date;
    try {
        date = $.datepicker.parseDate( dateFormat, element.value );
    } catch( error ) {
        date = null;
    }

    return date;
}




export function titleRow(type = 'titleRow')
{
    for(let i=0;i<10;i++){
        let x = CS.mySpreadSheet.sheet.data.rows._[i]
        if(x.type === type)
            return x
    }
    return false
}

export function saveChange(data){
    ajaxCall({
        url: route('page.saveCell'),
        data: JSON.stringify(data),
        contentType: 'text/json',
        dataType: 'json',
        processData: true,
    },function(response){
            CS.handleResponse(response);

        })
}


export function dataLoaded(newData) {
    CS.mySpreadSheet.loadData(newData);

//todo update jump
//     CS.mySpreadSheet.sheet.jump(1);
//     setTimeout(function () {
//         CS.mySpreadSheet.sheet.jump(newData.row.len-90);
//     }, 200);

    CS.showSuccess('Loading Finished')
}

export function handleResponse(out, src = '')
{
    console.log('Response',src, out)
    // if (out.reloadSubsheet) {
    //     CS.reloadSubsheet()
    //     return
    // }


    if( out.newImages){
        CS.addSubsheetImages(out.newImages)
    }
    if(out.newSubsheet)
    {
        CS.setCurrentSubsheet(out.newSubsheet)
    }

    if(out.filterTitle){
        let badge = $("<div>")
            .addClass('badge bg-dark my-0')
            .html('Showing: '+out.filterTitle[0]+' of '+out.filterTitle[1]+' Rows')
        $('.filterTitle').html(badge)
    }

    if(out.by === undefined || (out.by && CurrentUser.id !== out.by) || CS.isSuperAdmin()){

        $(out.success).each(function (){ CS.showSuccess(this.toString()) })

    }

    if((out.forSuperAdminOnly && CS.isSuperAdmin()) || !out.forSuperAdminOnly) {
        CS.showWarnings(out.warnings)
        CS.showErrors(out.errors)
    }

    // if(out.indexes.add)
    // {
    //     $(out.indexes.add).each(function(v,k){
    //         console.log("vk", v,k)
    //     })
    // }
    if(out.rowIndex) {
        CS.setIndexes('row', out.rowIndex)
        CS.setIndexes('col', out.colIndex)
    }



    if(out.newRows) {
        CS.injectNewRows(out.newRows)
    }
    if(out.newData) {
        CS.mySpreadSheet.loadData([{
            name: out.newData.name,
            rows: out.newData.rows,
            cols: out.newData.cols,
            col: out.newData.col,
            styles: out.newData.styles,
            validations: out.newData.validations,
            merges: out.newData.merges,
            freeze: out.newData.freeze,


        }]);

        CS.setNumberOfDataRows(out.newData.dataRowCount)

    }
    if(out.jumpToRow)
        CS.mySpreadSheet.sheet.jump(out.jumpToRow);

    if(out.deletedRow) {
            CS.markRowRemoved(CS.getRow(CS.getIndex('row')[out.deletedRow]))
    }

    if(out.updates) {
        $(out.updates).each(function () {
            const r = this;
            const ri = CS.getIndex('row')[r.rowID]
            const ci = CS.getIndex('col')[r.colID]
            if (ri === undefined || ci === undefined){
                console.log('co-ord not found', r)
                return //CS.showWarnings(['Co-ord not found.','Col Index:'+ci,'Row Index:'+ri])
            }
            let cell = CS.mySpreadSheet.cell(ri, ci)

            if(r.status && cell.text !== r.text && r.text !== null)
                CS.setCellStatus(r.status, cell)

            if(r.status && cell.status)
                CS.setCellStatus(r.status, cell)


            // if(r.forceStatus)
            //     CS.setCellStatus(r.forceStatus, cell)


            cell.text = r.text;
        })
        CS.mySpreadSheet.reRender()
    }

    if(out.Drawings){
        CS.Drawings.handleResponse(out)
    }
    if(out.Timesheet){
        CS.Timesheet.handleResponse(out)
    }
    if(out.Bids){
        CS.AddBid.handleResponse(out)
    }
}




export function formatCurrency(n) {
    if (!isNaN(parseFloat(n)) && isFinite(n)) {
        console.log('nan', n, !isNaN(parseFloat(n)) , isFinite(n))
        return n;
    }
    return parseFloat(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();
}

export function auto_grow(element) {
    element.style.height = "5px";
    element.style.height = (element.scrollHeight)+"px";
}
let contextID = 'app'
export function currentContextID(set){
    if(set)
        contextID = set

    return contextID
}

// =====================  Observer ===============================

export const observer = new MutationObserver(function(mutations) {
    $(observeList).each(function (key){
        if (document.contains(this.element)) {
            this.callback()
            console.log('OBS', this)
            observeList[key] = false;
        }

    })

    // $('input, select, textarea').each(function (){
    //     if($(this).hasClass('tracked')||$(this).hasClass('untracked')) return;
    //
    //     CS.startTrackingChanges(this);
    // });
});

export let observeList = []

export function addToObserveList(data){
    observeList.push(data)
}

export class CalcSys{
    constructor() {
        this.events()
    }

    events(){
        window.addEventListener('XSToolbarEvent',  this.handleXSToolbarEvent )

        $('calcButton').on('click', function(){

        })
    }

    handleXSToolbarEvent(ev){
        let detail = ev.detail
        console.log('handleXSToolbarEvent', detail)

        let cells = CS.getSelectedCells()
        let out = [];

        $(cells).each((k, v) => {
            out.push({
                rowid: CS.getRowUID(v.ri),
                colid: CS.getColUID(v.ci),
            })
        })

        let data = [];

        if(detail.type === 'clearformat'){
            data = {
                cells: out,
                sheetid: CS.currentSheetID,
                color: -1
            }


        }
        if(detail.type === 'bgcolor'){
            data = {
                cells: out,
                sheetid: CS.currentSheetID,
                color: detail.value
            }


        }


        if(data !== []){
            CS.ajaxCall({
                'url': route('page.setHighlight'),
                data: JSON.stringify(data),
                contentType: 'text/json',
                dataType: 'json',
                processData: false,
            }, CS.handleResponse)
        }


    }
}
