let changeLogObj
let changeLogToast

let changeLogParams = []

export function initChangeLogViewer(){
    changeLogObj = $('#changeLogToast')[0];
    changeLogToast = new window.Toast(changeLogObj, { autohide: false});

    $('.change-log-preset').on('click', function (){
        changeLogParams = {'from': $(this).data('num'), 'to': 0}
        CS.getFilteredData()
    })
}

export function openChangeLogViewer(reset = false){
    if(!changeLogToast.isShown()) changeLogToast.show()
    if(reset){
        $('#changeLogToast .toast-body tbody').html("<tr><td>Loading</td></tr>");
    }
}

export function hideChangeLogViewer(){
    changeLogToast.hide();
}

export function loadChangesForCell(colID){
    openChangeLogViewer(true);
    CS.makeCall({
        url: route('changes.cell.get', {id:colID}),
    },function (d){
        $('#changeLogToast .toast-body tbody').html('')
        $(d).each(function (){
            let newRow = $('<tr>');
            newRow.append($("<td>"+this.f+"</td>"))
            newRow.append($("<td>"+this.t+"</td>"))
            newRow.append($("<td>"+this.b+"</td>"))
            newRow.append($("<td>"+this.o+"</td>"))
            $('#changeLogToast .toast-body tbody').append(newRow);
        })
    })
}

export function getChangeLogParams(){
    return changeLogParams
}
