export default class {

    constructor() {
        console.log('Started Emails')
    }

    init(data){
        console.log('Emails Initiated', data)
        this.FoldersList = $('#FolderList')
        this.MessageList = $('#MessageList')
        this.MessageView = $('#MessageView')
        this.SideBar = $('#SideBar')

        this.fetchAccount()
    }

    fetchAccount(){

        CS.ajaxCall({
            url: route('getAccount'),
        }, (response) => {
            this.renderAccount(response)
        })
    }

    renderAccount(data){
        $(data.folderList).each((key, item) => {
            let messages = data.folders[item]

            let listing = this.addFolderListing(item)
            $(messages).each((key, msg) => {
                listing.append(this.addListMessage(msg))
            })
            this.addFolder(item)
                .on('click', () => {
                    this.focusOn('folder', listing)
                })



        })
        // this.setFolders(data.folderList)
    }

    // setFolders(data){
    //     $(data).each((key, item) => {
    //         this.addFolder(item)
    //     })
    // }

    addFolderListing(data){
        let cont = $('<div>')
            .addClass('folder-listing')

        this.MessageList.append(cont)

        return cont
    }

    addFolder(data){
        let cont = $('<div>')
            .html(data)
            .addClass('')

        this.FoldersList.append(cont)

        return cont
    }

    addListMessage(data){
        let msg = $('<div>')
            .html(data.body)
            .addClass('message')

        let cont = $('<div>')
            .html(data.subject + ' -- '+data.attachments)
            .on('click', () => {
                // this.focusOn('message', msg)
                this.MessageView.html(msg)

                this.setSideBar(data)
            })
        // this.MessageView.append(msg)
        return cont
    }

    focusOn(type, obj){

        if(type == 'folder'){
            $('.folder-listing').hide()

        }
        if(type == 'message'){
            $('.message').hide()
        }
        obj.show()
    }

    setSideBar(data){
        this.SideBar.html('')
        let attr = $('<div>')

        for(let key in data.attributes){
            let value = data.attributes[key]
            attr.append(this.renderAttrib(key,value))
        }

        if(data.attachments > 0){
            let button = $('<div>')
                .html('Get Attachments')
                .addClass('btn btn-success')
                .on('click', ()=>{

                })
            this.SideBar.append(button)

        }

        this.SideBar.append(attr)

    }

    renderAttrib(label, info){
        let l = $('<label>')
            .html(label)

        let v = $('<input>')
            .val(info)

        let out = $('<div>')
            .append(l,v)
        return out
    }

}
