
let uploadToast = false;
export function showProgressBar(text = 'Uploading File'){
    let a = $('<div>').html(text)
    a.append(createProgressBar())
    return CS.notif('warning',a);

}
export function createProgressBar(){
    let out = $('<div>').addClass('progress')
    let inner = $('<div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%">')

    out.append(inner)

    return out
}

export default { showProgressBar, createProgressBar}
