import {notif, showInfo} from "./notifs";
import {getChangeLogParams} from "./changeLog";

let currentCell
let editingCell

let touchedRows = []
let filterData = []
let clickWait
let copiedItems = []
let dateFormat = "d-M-yy"

let from;
let to;

export let currentSheetID
export let mySpreadSheet;

export function init(sheetID) {
    startListening(sheetID)
    CS.initChangeLogViewer()
    CS.initFilterModal()
    initSheet();

    CS.Sheet.init_bottomBar()


    currentSheetID = sheetID

    $('.loadDeleted').click(function () {
        makeCall('/data/deleted', {
            sheetID: CS.sheetID,

        }, function (x) {
            dataLoaded(x)
        }, 'text/json')
    })



    $('.saveTitleRowButton').on('click', function () {
        let styles = CS.mySpreadSheet.sheet.data.styles
        for(let x in styles){
            if(styles[x] instanceof Array){
                styles[x] = {...styles[x]}
            }
        }
        CS.makeCall({
            url: route('page.saveLayout'),
            contentType: 'application/json',
            dataType: 'json',
            processData: true,
            data: JSON.stringify({
                sheetID: sheetID,
                data: CS.titleRow(),
                ldata: CS.titleRow('labelRow'),
                cdata: CS.getRow(4),
                styles: styles,
                cols: CS.mySpreadSheet.sheet.data.cols._
            })
        }, CS.handleResponse)
    })


    $('.open_row_calc').on('click', function () {
        window.open('/xcalcRow/' + sheetID + '/' + CS.currentRow()['uid'], 'new')
    })

    initSheetEvents()

    getFilteredData()
}

export function getFilteredData(){
    let n = notif('warning', 'Loading')
    let out = { filters: CS.getFilter(), sheetID: currentSheetID }
    out.default = $('[name="default"]:checked').val()
    out.limit = $('[name="limit"]').val()
    out.showDeleted = $('[name="showDeleted"]:checked').val() === "1"
    out.changes = CS.getChangeLogParams()

    CS.ajaxCall({
        url: route('page.getFiltered'),
        data: JSON.stringify(out),
        contentType: 'application/json',
        dataType: 'json',
        processData: true,
    }, function (data) {
        n.hide()
        CS.handleResponse(data)
    });
}

export function injectNewRows(rows) {
    let dataRows = CS.mySpreadSheet.sheet.data.rows

    $(rows).each(function (i, v) {
        let rowNumber = CS.getIndex('row')[v.orig_uid] ?? CS.getIndex('row')[v.uid] ?? CS.numberOfDataRows

        dataRows._[rowNumber].uid = v.uid
        dataRows._[rowNumber].new = false

        if(!CS.getIndex('row')[v.uid]) {
            CS.setNumberOfDataRows(CS.numberOfDataRows + 1)
            CS.pushToIndex('row', v.uid, rowNumber)
        }
    })

    CS.mySpreadSheet.reRender();
}

export function getSelectedRange() {
    return CS.mySpreadSheet.datas[0].selector.range
}

export function deleteFullySelectedRows(verified) {
    let rows = CS.getFullySelectedRows()
    if (!verified) {
        let add = rows.length > 1 ? rows.length + ' rows?' : 'row?'
        CS.confirm('Are you sure you want to delete ' + add, () => {
            CS.deleteFullySelectedRows(true)
        })
    } else {
        let rowList = []
        $(rows).each((i, v) => {
            rowList.push(v.uid)
        })
        CS.makeCall({
            data: JSON.stringify({
                sheetID: CS.currentSheetID,
                rowIDs: rowList,
            }),
            url: route('page.deleteRows'),
            contentType: 'text/json',
            dataType: 'json',
            processData: true,
        })
    }
}

export function getFullySelectedRows() {
    let range = CS.getSelectedRange()
    if (range.sri === range.eri && range.sci === range.eci) {
        return []
    }

    let out = []
    let r_index = range.sri
    while (range.eri >= r_index) {
        let row = getRow(r_index)
        let diff = range.eci - range.sci + 1
        if (diff === row.cells.length) {
            out.push(row)
        }
        r_index++
    }

    return out
}

export function getSelectedCells() {
    let range = CS.getSelectedRange()
    if (range.sri === range.eri && range.sci === range.eci) {
        return [CS.mySpreadSheet.cell(range.sri, range.sci)]
    }
    let out = []
    let r_index = range.sri
    while (range.eri >= r_index) {
        let c_index = range.sci
        while (range.eci >= c_index) {
            let x = CS.mySpreadSheet.cell(r_index, c_index)
            x.ri = r_index
            x.ci = c_index
            out.push(x)
            c_index++
        }
        r_index++
    }

    return out
}

export function loadExample() {
    const rows10 = {len: 1000};
    for (let i = 0; i < 1000; i += 1) {
        rows10[i] = {
            cells: {
                0: {text: 'A-' + i},
                1: {text: 'B-' + i},
                2: {text: 'C-' + i},
                3: {text: 'D-' + i},
                4: {text: 'E-' + i},
                5: {text: 'F-' + i},
            }
        };
    }
    const rows = {
        len: 80,
        1: {
            cells: {
                0: {text: 'testingtesttestetst'},
                2: {text: 'testing'},
            },
        },
        2: {
            cells: {
                0: {text: 'render', style: 0},
                1: {text: 'Hello'},
                2: {text: 'haha', merge: [1, 1]},
            }
        },
        8: {
            cells: {
                8: {text: 'border test', style: 0},
            }
        }
    };
    // x_spreadsheet.locale('zh-cn');
    // var xs = x_spreadsheet('#demo', {showToolbar: true, showGrid: true})
    CS.mySpreadSheet.loadData([{
        freeze: 'B3',
        styles: [
            {
                bgcolor: '#f4f5f8',
                textwrap: true,
                color: '#900b09',
                border: {
                    top: ['thin', '#0366d6'],
                    bottom: ['thin', '#0366d6'],
                    right: ['thin', '#0366d6'],
                    left: ['thin', '#0366d6'],
                },
            },
        ],
        merges: [
            'C3:D4',
        ],
        cols: {
            len: 10,
            2: {width: 200},
        },
        rows,
    }, {name: 'sheet-test', rows: rows10}]).change((cdata) => {
        // console.log(cdata);
        console.log('>>>', xs.getData());
    });

    CS.mySpreadSheet.on('cell-selected', (cell, ri, ci) => {
        console.log('cell:', cell, ', ri:', ri, ', ci:', ci);
    }).on('cell-edited', (text, ri, ci) => {
        console.log('text:', text, ', ri: ', ri, ', ci:', ci);
    }).on('pasted-clipboard', (data) => {
        console.log('>>>>>data is ', data);
    });

    setTimeout(() => {
        // xs.loadData([{ rows }]);
        CS.mySpreadSheet.cellText(14, 3, 'cell-text').reRender();
        console.log('cell(8, 8):', CS.mySpreadSheet.cell(8, 8));
        console.log('cellStyle(8, 8):', CS.mySpreadSheet.cellStyle(8, 8));
    }, 5000);

    //
    // from = $( "#from" )
    //     .datepicker({
    //         defaultDate: "+1w",
    //         changeMonth: true,
    //         numberOfMonths: 3,
    //         dateFormat: "d-M-yy",
    //     })
    //     .on( "change", function() {
    //         to.datepicker( "option", "minDate", getDate( this ) );
    //     })
    // to = $( "#to" ).datepicker({
    //     defaultDate: "+1w",
    //     changeMonth: true,
    //     numberOfMonths: 3,
    //     dateFormat: "d-M-yy",
    // }).on( "change", function() {
    //     from.datepicker( "option", "maxDate", getDate( this ) );
    // });

}

export function initSheet(opts) {
    opts = {
        ...{
            mode: 'edit', //{{ $sheet->type === 'Normal' ? 'edit' : 'read' }}', // edit | read
            view: {
                height: () => document.documentElement.clientHeight - 120,
                width: () => document.documentElement.clientWidth,
            },
            negativeColor: '#F3101BFF',
            showContextmenu: true,
            showToolbar: CS.isAdmin,
            showBottomBar: false,
            // styles: [
            //     {
            //         bgcolor: '#f4f5f8',
            //         textwrap: false,
            //         color: '#900b09',
            //         border: {
            //             top: ['thin', '#0366d6'],
            //             bottom: ['thin', '#0366d6'],
            //             right: ['thin', '#0366d6'],
            //             left: ['thin', '#0366d6'],
            //         },
            //     },
            // ],
        }, ...opts
    }
    mySpreadSheet = new x_spreadsheet(document.getElementById('demo'), opts);
}

export function markRowRemoved(row) {
    $(row.cells).each(function () {
        updateCellStyle(this, stylesBorder('#ff4848'))
        this.editable = false
    })

    CS.mySpreadSheet.reRender()


}

export function getRow(num) {
    return CS.mySpreadSheet.sheet.data.rows._[num]
}

export function getRowUID(ii) {
    let i = CS.getIndex('row')
    for(let x in i){
        if(i[x] === ii) return x
    }
    return 0
}
export function getColUID(ii) {
    let i = CS.getIndex('col')
    for(let x in i){
        if(i[x] === ii) return x
    }
    return 0
}
export function getCell(ri, ci) {
    return CS.mySpreadSheet.cell(ri, ci)
}

export function setRow(num, row) {
    CS.mySpreadSheet.sheet.data.rows._[num] = row
}

export function getCurrentCell() {
    return currentCell
}

export function setCurrentCell(x) {
    currentCell = x
}

export function setNumberOfDataRows(x) {
    numberOfDataRows = x
}

export let numberOfDataRows = false;

export function currentUser(x = false) {
    if (x === false) return currentUserObj
    currentUserObj = x
}

let currentUserObj = false;

export function currentRow() {
    if(!currentCell) return
    return CS.mySpreadSheet.sheet.data.rows._[currentCell.ri]
}

function startListening(sheetID) {
    Echo.channel('sheet.' + sheetID)
        .listenToAll((eventName, response) => {
            CS.handleResponse(response, 'websocket')
        });


    Echo.join(`sheet.${sheetID}`)
        .here((users) => {
            console.log('here',users);
            CS.User.setActiveUsers(users)
        })
        .joining((user) => {
            console.log('joining',user.name);
            // notif('success', user.name+' has opened this sheet')
            CS.User.addActiveUser(user)
        })
        .leaving((user) => {
            console.log('leaving',user.name);
            // notif('warning', user.name+' has closed this sheet')
            CS.User.removeActiveUser(user)

        })
        .error((error) => {
            console.error('wht?',error);
        });
}

let stylesBorder = (color) => {
    return {
        "border": {
            "bottom": [
                "medium",
                color
            ],
            "top": [
                "medium",
                color
            ],
            "left": [
                "medium",
                color
            ],
            "right": [
                "medium",
                color
            ]
        }
    }
}
let celStatuses = {
    good: stylesBorder("#00ff00"),
    bad: stylesBorder("#ff0000"),
    warning: stylesBorder("#eacb00"),
}

export function setCellStatus(status, cell = false) {
    cell = cell ?? currentCell
    cell.status = status
    if (status === 'good') {
        updateCellStyle(cell, celStatuses.good)
    }
    if (status === 'warning') {
        updateCellStyle(cell, celStatuses.warning)
    }

    if (status === 'bad') {
        updateCellStyle(cell, celStatuses.bad)
    }

    CS.mySpreadSheet.reRender()

}


export function updateCellStyle(cell, newStyle) {
    if (cell.oStyle === undefined || cell.oStyle === false)
        cell.oStyle = cell.style

    let style = sheetStyles()[cell.style]

    let ns = {...style, ...newStyle}

    cell.style = sheetStyles().push(ns) - 1
}


export function sheetStyles() {
    return CS.mySpreadSheet.datas[0].styles

}

export function setBottomBar(data){


}

export function initSheetEvents(){
    let sheetID = currentSheetID
    CS.mySpreadSheet.change(data => {
    });

    CS.mySpreadSheet.on('copied', (e, t) => {
        copiedItems = CS.getSelectedCells()
        document.execCommand("copy")

    })

    let pasteEvent = (e,t) => {
        let selectedCells = CS.getSelectedCells()
        let changes = []

        if (copiedItems.length === 0) {
            navigator.clipboard.readText().then(function (clipData){
                for(let rowOffset in clipData.split("\n")) {
                    for (let colOffset in clipData.split("\n")[rowOffset].split("\t")) {
                        let ci = parseInt(currentCell.ci) + parseInt(colOffset)
                        let ri = parseInt(currentCell.ri) + parseInt(rowOffset)
                        let cell = CS.getCell(ri,ci)
                        CS.setCellStatus('warning', cell)
                        let row = CS.getRow(ri)

                        changes.push({
                            sheetID: sheetID,
                            rowID: row.uid,
                            colID: CS.titleRow().cells[ci].uid,
                            text:  clipData.split("\n")[rowOffset].split("\t")[colOffset]
                        })
                    }
                }
                if (changes.length > 0) {
                    CS.saveChange({
                        bulkSave: changes
                    })
                }
            })

        }
        else if (copiedItems.length === 1 && selectedCells.length > 1) {
            $(selectedCells).each(function () {
                const row = CS.getRow(this.ri)
                if (row && row.type !== 'titleRow') {
                    CS.setCellStatus('warning', this)

                    changes.push({
                        sheetID: sheetID,
                        rowID: row.uid,
                        colID: CS.titleRow().cells[this.ci].uid,
                        text: this.text
                    })
                }
            })

        } else if (copiedItems.length > 1 && selectedCells.length === 1) {

            let orig = false
            $(copiedItems).each(function () {

                const row = CS.getRow(this.ri)
                if (row && row.type !== 'titleRow') {
                    if (!orig) orig = this
                    let newRowNum = currentCell.ri + (this.ri - orig.ri)
                    let newColNum = currentCell.ci + (this.ci - orig.ci)
                    let newRow = CS.getRow(newRowNum)
                    CS.setCellStatus('warning', CS.getCell(newRowNum, newColNum))

                    changes.push({
                        sheetID: sheetID,
                        rowID: newRow.uid,
                        colID: CS.titleRow().cells[newColNum].uid,
                        text: this.text
                    })
                }
            })

        } else {
            const row = CS.getRow(selectedCells[0].ri)
            if (row && row.type !== 'titleRow') {
                CS.setCellStatus('warning', CS.getCell(currentCell.ri, currentCell.ci))
                changes.push({
                    sheetID: sheetID,
                    rowID: getRow(currentCell.ri).uid,
                    colID: CS.titleRow().cells[currentCell.ci].uid,
                    text: selectedCells[0].text
                })
            }
        }
// console.log('changes',changes)
        if (changes.length > 0) {
            CS.saveChange({
                bulkSave: changes
            })
        }
    }

    CS.mySpreadSheet.on('pasted', pasteEvent)
    CS.mySpreadSheet.on('pasted-clipboard', pasteEvent)

    CS.mySpreadSheet.on('delete-cell-text', (ri, ci) => {
        let fullySelectedRows = CS.getFullySelectedRows()

        if (fullySelectedRows.length > 0) {

            CS.deleteFullySelectedRows()
            return;
        }
        let cells = CS.getSelectedCells()
        $(cells).each(function () {
            const row = CS.getRow(this.ri)
            if (row && row.type !== 'titleRow')
                CS.saveChange({
                    sheetID: sheetID,
                    rowID: row.uid,
                    colID: CS.titleRow().cells[this.ci].uid,
                    text: ''
                })

        })

    });
    CS.mySpreadSheet.on('cell-edit-finished', (text, ri, ci) => {
        const cell = CS.mySpreadSheet.cell(ri, ci);
        if(cell.text === cell.origText) return;


        const row = currentRow()//CS.mySpreadSheet.sheet.data.rows._[ri]
        if (row.type !== 'titleRow' && row.type !== 'labelRow') {
            setCellStatus('warning', cell)
            CS.saveChange({
                sheetID: sheetID,
                rowID: row.uid,
                colID: CS.titleRow().cells[ci].uid,
                text: text
            })
        }
    });
    CS.mySpreadSheet.on('cells-selected', (cell, {sri, sci, eri, eci}) => {

        let x = sri;
        let sum = 0;//parseFloat(CS.mySpreadSheet.sheet.data.getCellTextOrDefault(sri, sci).replace(/[^0-9\-\.]/g, ''));
        let avg = 0;
        let count = 0;
        let rows = CS.mySpreadSheet.getData()[0].rows;
        let isCurrency = false
        while (x <= eri) {
            touchedRows[x.toString()] = rows[x];
            let origVal = CS.mySpreadSheet.sheet.data.getCellTextOrDefault(x, sci)
            let val = origVal.replace(/[^0-9\-\.]/g, '');
            count++;
            if (!Number.isNaN(val) && val !== '') {

                sum += parseFloat(parseFloat(val).toFixed(2));
                avg = parseFloat(parseFloat(sum / count).toFixed(2));
            }
            if(origVal[0] === '$')
                isCurrency = true

            x++
        }
        // sum = Intl.NumberFormat('en-US').format(sum)
        // $('.bottomToolbar .sum').html(sum.toFixed(2));
        // $('.bottomToolbar .avg').html(avg);
        // $('.bottomToolbar .count').html(count);

        if(isCurrency){
            sum = currency(sum).format()
            avg = currency(avg).format()
        }

        CS.Sheet.updateInfoBox('sum', sum)
        CS.Sheet.updateInfoBox('avg', avg)
        CS.Sheet.updateInfoBox('count', count)

    });
    CS.mySpreadSheet.on('cell-dblclicked', (cell, evt) => {
        clearTimeout(clickWait)
        editingCell = cell;
        let row = CS.getRow(cell.ri)

        // if(cell.ci === 0 && cell.editable == false)
        //     CS.showInfo('This cell is automatically generated when you save.',{timeout: 2000})

        if (cell.type === 'file') {
            CS.openFileManager()

        }

        if (cell.type === 'subsheet') {
            if (row.new === true) {
                CS.showInfo('Please Enter some data into the first column to make subsheets available')
                return;
            }
            CS.loadSubsheet({rowID: row.uid, colID: cell.uid})
        }
        if (cell.type === 'filter') {
            CS.openFilterModal(cell);
        }

    });
    CS.mySpreadSheet.on('cell-selected', (cell, ri, ci) => {
        if (!cell) return;


        clickWait = setTimeout(function () {


            if (cell.changed) {
                CS.loadChangesForCell(cell.changed)
            } else {
                CS.hideChangeLogViewer()
            }
        }, 250)

        let row = CS.getRow(ri)
        if (cell.type === 'file') {
            CS.loadIntoFileManager({
                rowID: row.uid,
                colID: cell.uid,
            })
        }


        if (cell.text && !cell.origText)
            cell.origText = cell.text;

        if (currentCell === cell && cell) {


        } else if (!currentCell) {
            cell.ri = ri;
            cell.ci = ci;
            currentCell = cell;
        } else {

            if (currentCell && currentCell.text && currentCell.subType && currentCell.text !== '') {
                if (currentCell.subType.includes('percent'))
                    CS.mySpreadSheet.cellText(currentCell.ri, currentCell.ci, currentCell.text.replace('%', '') + '%').reRender();

                if (currentCell.subType.includes('hours'))
                    CS.mySpreadSheet.cellText(currentCell.ri, currentCell.ci, currentCell.text.replace(' hrs', '') + ' hrs').reRender();

                if (currentCell.subType.includes('time')) {
                    let val = currentCell.text.replace(/[^0-9:]/g, '');
                    if (!val.includes(':')) {
                        if (val > 99) {
                            val = val.substr(0, 1) + ':' + val.substr(1, 2);

                        } else
                            val += ':00';
                    }
                    CS.mySpreadSheet.cellText(currentCell.ri, currentCell.ci, val).reRender();
                }

                if (currentCell.subType.includes('rate'))
                    CS.mySpreadSheet.cellText(currentCell.ri, currentCell.ci, '$' + currentCell.text.replace('$', '').replace('/hr', '') + '/hr').reRender();

                if (currentCell.subType.includes('currency'))
                    CS.mySpreadSheet.cellText(currentCell.ri, currentCell.ci, new Intl.NumberFormat('en-CA', {
                        style: 'currency',
                        currency: 'CAD'
                    }).format(currentCell.text.replace('$', '').replace(',', ''))).reRender();

                if (currentCell.subType.includes('date'))
                    CS.mySpreadSheet.cellText(currentCell.ri, currentCell.ci, currentCell.text.replace(/ /g, '-')).reRender();

                if (currentCell.subType.includes('projNum'))
                    CS.mySpreadSheet.cellText(currentCell.ri, currentCell.ci, Number(currentCell.text).toFixed(2)).reRender();
            }


            cell.ri = ri;
            cell.ci = ci;
            currentCell = cell;

            let out = cell.text
            if(cell.text[0] === '$'){
                out = currency(currentCell.text).format()
            }else{
                out = out.replace(/[^0-9:]/g, '')
            }
            out = out == '' ? 0 : out
            CS.Sheet.updateInfoBox('sum', out)
            CS.Sheet.updateInfoBox('avg', out)
            CS.Sheet.updateInfoBox('count', 1)
        }


        CS.Sheet.updatePreviewLine(cell.text)
        CS.Sheet.getCellInfo(CS.currentSheetID, CS.getRowUID(ri), CS.getColUID(ci))

    });
}

export default class {
    bottomBarElements = {}
    constructor() {
        this.previewLineEvents()
    }

    init_bottomBar(){
        let bar = CS.Sheet.makeInfoContainer()
        let activeUser = CS.Sheet.makeInfoBox('Users Viewing This Page','')

        bar.append(activeUser)
        $('.bottomToolbar .middle').append(bar)

        bar = CS.Sheet.makeInfoContainer()
        let sum = CS.Sheet.makeInfoBox('Sum','0')
        let count = CS.Sheet.makeInfoBox('Count','0')
        let avg = CS.Sheet.makeInfoBox('Average','0')

        this.bottomBarElements = {
            sum: sum,
            count: count,
            avg: avg,
            activeUser: activeUser
        }

        bar.append(count,sum,avg)
        $('.bottomToolbar .right').append(bar)
    }

    updatePreviewLine(newVal){
        $('#previewLine')
            .val(newVal)
            .data('old_val', newVal)
    }

    previewLineEvents(){
        $('#previewLine').on('blur', function (){
            if($(this).data('old_val') !== $(this).val()){

            }
        })
    }
    showingCellInfo = ''
    getCellInfo(sheetID, rowID, colID){
        let key = rowID+'-'+colID
        if(this.showingCellInfo === key) return
        this.showingCellInfo = key
        CS.ajaxCall({
            url: route('page.getCellInfo'),
            data: {sheet: sheetID, row: rowID, col: colID}
        }, function (response){
            CS.Sheet.renderCellInfo(response)
        })
    }

    renderCellInfo(data){
        if(!data) return
        let bar = this.makeInfoContainer()
        if(data.created_at){
            bar.append(this.makeInfoBox('Row Created',data.created_at + ' by ' + data.created_by))
        }
        if(data.modified_at){
            bar.append(this.makeInfoBox('Cell Modified',data.modified_at + ' by ' + data.modified_by))
        }
        if(data.deleted_at){

            bar.append(this.makeInfoBox('Row Deleted',data.deleted_at + ' by ' + data.deleted_by))

        }


        $('.bottomToolbar .left').html(bar)
    }

    updateInfoBox(name, value){
        let cont = this.bottomBarElements[name]
        if(cont)
            $('.boxy', cont).html(value)
    }

    makeInfoContainer(){
        let bar = $('<ul>').addClass('list-inline mb-0')

        return bar
    }

    makeInfoBox(title, value){
        return $('<li>')
            .addClass('list-inline-item ms-1')
            .append($('<span>').html(title).addClass('badge badge-sm bg-secondary badge-title'))
            .append($('<span>').html(value).addClass('boxy px-2'))
    }
}
