import Sortable from "sortablejs";
import {makeCall} from "../calcsys";
import {dateFormat} from "./common";
let ctx = false;

let invoiceTemplate
let tableColumns
let tableRows = []
let tableRowList = false;
let balanceDueContainer = false;
let supportingData = [];

export function initInvoices(resp){
    // supportingData = resp.data.supportingData
    // invoiceTemplate[0][0].options = supportingData.contractors
    tableRows = []
    supportingData['customers'] = resp.data.customerData
    console.log(resp)
    invoiceTemplate = resp.data.template
    tableColumns = resp.data.tableColumns
    ctx = $('#invoicesContainer')
    balanceDueContainer = $('#balanceDueContainer')

    $('#saveBtn', ctx).on('click', saveInvoice)
}

export function loadInvoicesList(data){
    console.log(data)
}

export function loadInvoice(data){

    if(data.new){

    }

    tableRowList = $('<ul>')
        .addClass('')

    for(let x = 0; x < invoiceTemplate.length; x++){
        $('#row'+(x+1)).append(
            createRow(invoiceTemplate[x])
        )
    }

    loadTable(data)


    loadBalanceDue()
    if(!data.rows || data.rows.length === 0){

        addRow()
        addRow()
    }


    CS.updateSums()
    setDateLogic()

}

function setDateLogic(){
    let term = $('[name="term"]')

    term.on('change', function(){
        console.log('term!')
        calcDueDate()
    })


    calcDueDate()

}

function calcDueDate(){
    let invoiceDate = $('[name="invoiceDate"]')
    let dueDate = $('[name="dueDate"]')
    let term = $('[name="term"]')

    if(invoiceDate.val() === '') {

        let now = new Date()
        invoiceDate.val(now.format())

        let due = now.addDays(term.val())
        dueDate.val(due.format())
    }
}

function loadBalanceDue(){
    let title = $('<span>').html('BALANCE DUE')
    let h1 = $('<h1>')
        .addClass('total')
        .html('$0.00')

    let out = $('<div>')

    out.append(title).append(h1)

    balanceDueContainer.html(out)
}

function loadTable(data){
    let tableContainer = $('#tableContainer').html('')

    tableContainer.append(createTableHeader())

    tableContainer.append(tableRowList)
    console.log('loading...:', data)
    $(data.rows).each(function(){
        tableRowList.append(createTableRow(this))

    })

    Sortable.create(tableRowList[0],{
        handle: '.sortHandle',
        animation: 300
    })

    loadTableControls()
}

export function collectData(context){
    let out = $('input, textarea, select','.inputContainer').serializeArray()

    let rowData = [];
    $('.dataRow', context).each((i,v) => {
        let d = $(v).find('input, select, textarea').serializeArray()
        rowData.push(cleanupSerial(d))
    })


    return {inputs: cleanupSerial(out), rowData: rowData}
}

export function cleanupSerial(data){
    let out = {}
    $(data).each((k,v)=>{
        if(out[v.name]){
            if(Array.isArray(out[v.name]))
                out[v.name].push(v.value)
            else{
                let x = []
                x.push(out[v.name],v.value)
                out[v.name] = x
            }
        }
        else
            out[v.name] = v.value
    })

    return out
}

export function saveInvoice(){
    let data = collectData(ctx)

    console.log(data)

    if(CS.getCurrentSubsheet().id) {
        data['subSheetID'] = CS.getCurrentSubsheet().id
    }else{
        data['colID'] = CS.getCurrentSubsheet().colID
    }

    CS.clearTrackedChanges()

    CS.makeCall({
        url: route('subsheet.save'),
        data: JSON.stringify(data),
        contentType: 'application/json'
    }, () => {
        CS.showSuccess('Saved!')
    })

}

function addRow(){
    tableRowList.append(createTableRow())

}

function loadTableControls(){
    let addRowBtn = $('<span>')
        .addClass('btn btn-outline-secondary btn-sm')
        .html('Add lines')
        .on('click', addRow)

    $('#tableControls').append(addRowBtn)
}

function createTableHeader(){
    let out = $('<div>')
        .addClass('row headerRow')
    let commonClass = 'border-1 border border-top-0 border-gray';

    let leftGutter = $('<div>')
        .addClass('col shortCol')
        .addClass('border-start-0')
        .addClass(commonClass)

    out.append(leftGutter)

    let lineCounter = $('<div>')
        .addClass('col shortCol text-end')
        .addClass(commonClass)
        .html('#')

    out.append(lineCounter)

    $(tableColumns).each(function (){
        let col = $('<div>')
            .addClass(this.class)
            .addClass(commonClass)
            .html(this.title)

        out.append(col)
    })


    let rightGutter = $('<div>')
        .addClass('col shortCol')
        .addClass('border-end-0')
        .addClass(commonClass)


    out.append(rightGutter)

    return out
}

function createTableRow(rowData = []){
    let out = $('<li>')
        .addClass('row dataRow')

    let commonClass = 'border-1 border border-gray';

    let leftGutter = $('<div>')
        .addClass('col shortCol p-2 sortHandle border-start-0')
        .addClass(commonClass)
        .html('<i class="fa-solid fa-list-ul"></i>')

    out.append(leftGutter)

    let lineCounter = $('<div>')
        .addClass('col shortCol text-end p-2')
        .addClass(commonClass)
        .html(tableRows.length+1)

    out.append(lineCounter)


    $(tableColumns).each(function (){
        let col = CS.createCol(rowData,this,'')
            .addClass(this.class)
            .addClass(commonClass)


        out.append(col)
    })


    let rightGutter = $('<div>')
        .addClass('col shortCol p-2')
        .addClass('border-end-0')
        .addClass(commonClass)
        .html('<i class="fa-solid fa-trash"></i>')
        .on('click',function () {
            tableRows.pop()
            $(this).parent().remove();
        });

    out.append(rightGutter)

    tableRows.push(out)

    return out
}

function createRow(inputs){

    let stack = $('<div>')
        .addClass('hstack gap-3')

    $(inputs).each(function (){
        let inp = CS.createFromInput(this)
        stack.append(inp)
    })


    return stack
}


export function createInvoiceBaselineHTML(){
    let out = $(`<div class="container-fluid" id="invoicesContainer">
        <div class="row">
            <div class="col inputContainer" id="row1"></div>
            <div class="col-2 text-end" id="balanceDueContainer"></div>
        </div>
        <div class="row">
            <div class="col inputContainer" id="row2"></div>
        </div>
        <div class="bg-white">

            <div class="row">
                <div class="col" id="tableContainer"></div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="" id="tableControls"></div>
                </div>
                <div class="col">
                </div>
                <div class="col-3">
                    <div class="" id="totalsContainer">
                        <div class="row" id="">
                            <div class="col">Subtotal</div>
                            <div class="col text-end">
                                <span class="subTotal"></span>
                            </div>
                        </div>
                        <div class="row" id="">
                            <div class="col">HST (ON) @ 13% on <span class="subTotal"></span></div>
                            <div class="col text-end"><input type="text" class="taxTotal text-end"></div>
                        </div>
                        <div class="row" id="">
                            <div class="col">Total</div>
                            <div class="col text-end">
                                <span class="total"></span>
                            </div>
                        </div>
                        <div class="row" id="">
                            <div class="col">Balance due</div>
                            <div class="col text-end">
                                <span class="total"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-3 inputContainer" id="row3"></div>
                <div class="col" id="row4"></div>
            </div>
            <div class="row">
            </div>
        </div>
    </div>`)

    return out
}

export function getSupportingData(){
    return supportingData
}
