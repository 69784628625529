 export default class UserClass{
     activeUsers = []

    constructor() {
        this.activeUsers = []


    }

    setActiveUsers(list){
        $('.activeUsersCount').html(this.activeUsers.length)

        $(list).each(function(a,v){

            CS.User.addActiveUser(v)

        })
    }

    addActiveUser(data){
        this.activeUsers[data.id] = data

        this.renderActiveUserList()
        this.resetActiveUserCount()

    }

    removeActiveUser(data){
        $('#activeUser-'+data.id).remove()
        delete this.activeUsers[data.id]

        this.resetActiveUserCount()
        this.renderActiveUserList()

    }

    resetActiveUserCount(){
        $('.activeUsersCount').html($('#ActiveUsersContainer .activeUser').length)

    }

    renderActiveUserList(){
        let out = []

        $(this.activeUsers).each((a,v) => {
            if(v)
                out.push(v.name)
        })

        CS.Sheet.updateInfoBox('activeUser', out.join(', '))
    }

}

// export let eUser = new UserClass()
