import currency from "currency.js";
import {updateTotals} from "../common";
import {formatCurrency} from "../../calcsys";

// export let SlimSelectSettings = {
//     placeholderText: '...',
//     contentPosition: 'relative',
//     showOptionTooltips: true,
//     openPosition: 'auto', // 'auto', 'up' or 'down'
//     // contentLocation: document.getElementById('app')
// }

// export let SlimSelectEvents = {
//     beforeOpen: () => {
//         console.log('before open')
//     },
// }

export function createCol(data, tableData, nameSuffix = '' ){
    let sendToObserver = false
    let container = $('<div>')
        .addClass(tableData.class)

    let col = $('<input>')
        .attr('type', 'text')
        .val(data[tableData.name])

    if(tableData.type === 'select') {
        col = createSelect(tableData, data[tableData.name])
    }

    if(tableData.type === 'textarea') {
        col = $('<textarea>')
            .on('input', function (e) {
                CS.auto_grow(this)
            }).html(data[tableData.name])

        sendToObserver = {
            callback: function(){
                CS.auto_grow(col[0])
            },
            element: col[0]
        }
    }

    if(tableData.type === 'number') {
        col.attr('type', 'number')
    }

    if(tableData.type === 'date') {

        sendToObserver = {
            callback: function(){
                datepicker(col[0], CS.dpOptions)
            },
            element: col[0]

        }
    }

    if(tableData.type === 'currency') {
        col.addClass('currency')
            .val(currency(data[tableData.name]).format())
            .on('keyup', updateTotals)
            .on('blur',  () => {
                let thisValue = currency(col.val())
                col.val(thisValue.format())
                CS.updateTaxes(col)
                // if ($(this).attr('name') === 'cost[]') {
                //     CS.updateTaxes($(this))
                // }
                CS.updateSums()
            })
            .on('focus', function () {
                var $this = $(this);
                $this.select();

                window.setTimeout(function () {
                    $this.select();
                }, 1);

                // Work around WebKit's little problem
                function mouseUpHandler() {
                    // Prevent further mouseup intervention
                    $this.off("mouseup", mouseUpHandler);
                    return false;
                }

                $this.mouseup(mouseUpHandler);

            });
    }

    col.attr('name', tableData.name+nameSuffix)
        .attr('data-name', tableData.name)

    if(tableData.inputClass)
        col.addClass(tableData.inputClass)

    if(sendToObserver) CS.addToObserveList(sendToObserver)

    CS.startTrackingChanges(col);

    if(tableData.disabled){
        col.attr('disabled', 'disabled')
        let hcol = $('<input>')
            .attr('name', tableData.name+nameSuffix)
            .attr('type', 'hidden')
            .val(data[tableData.name])
        container.append(hcol)
    }

    if(tableData.type === 'button') {
        col = $('<div>')
            .addClass('removeRow')
            .html('<i class="fa-solid fa-delete-left"></i>')
            .on('click',function () {
                $(this).parent().parent().remove();
            });
    }
    // if(tableData.type === 'text') {
    //     col = $('<div>')
    //         // .addClass('removeRow')
    //         .html(data[tableData.name])
    //         .on('click',function () {
    //             $(this).parent().parent().remove();
    //         });
    // }


    return container.append(col)
}
function createSelect(data, origVal){
    if(data.name === 'projectNumber' && origVal) {
        origVal = parseFloat(origVal).toFixed(2)
    }
    let sel = $('<select>')
        .attr('name', data.name)
        .addClass(data.inputClass)

    sel.append(
        $('<option value="">')
            .attr('data-placeholder', 'true')
            .html('Start typing to filter')
    )
    let opts = data.options
    let ssdata = []
    for(let value in opts ){
        let title = opts[value]

        let opt = $('<option>')
            .val(value)
            .html(title)
            // .attr('title', '???')
        if((value === data.value || value === origVal || title === data.value || title === origVal) && title !== '') {
            opt.attr('selected', true)
        }
        sel.append(opt)
    }
    let selOnChange = null;
    console.log('?>?', data.name)
    if(data.name === 'customer'){
        selOnChange =  function(newVal) {

            let info = CS.getSupportingData()['customers'][newVal]

            console.log('custChang', newVal, info)
            if(!info) return
            $('[name="customerEmail"]').val(info.Email).trigger('change')
            $('[name="billingAddress"]').val(info.Address).trigger('change')
        }
    }

    CS.addToObserveList({
        callback: ()=>{
            let x = $('<div>').addClass('ssContentDiv')
            sel.parent().append(x)

            let z = startSelect(sel[0], {
                onChange: selOnChange
            })
            // console.log('??',z)
            // CS.startTrackingChanges(sel);
            // startSelect({
            //     select: sel[0],
            //     settings: { ...{
            //         showSearch: data.searchable??false,
            //         contentLocation: x.get()[0]//document.getElementById(CS.currentContextID())
            //     },...CS.SlimSelectSettings},
            //
            // })
            // sel.trigger('change')
        },
        element: sel[0],
    })

    return sel
}
export function createFromInput(data, modifier){
    let out = $('<div>')
        .addClass(data.containerClass)

    let label = $('<label>')
        .html(data.title)

    let inp = $('<input>')
        .attr({name: data.name, type: 'text', value: data.value??''})
        .addClass(data.inputClass)

    if(modifier){
        inp = modifier(inp)
    }

    if(data.type === 'select'){
        let sel = createSelect(data,)
        CS.startTrackingChanges(sel);
        if(data.class)
            out.addClass(data.class)
        return out.append(label).append(sel)//.append(inp);
    }else{
        inp.addClass('form-control form-control-sm')
    }

    if(data.type === 'textarea'){
        inp = $('<textarea>')
            .attr('name', data.name)
            .addClass(data.inputClass)

        if(data.value)
            inp.html(data.value)
    }
    if(data.type === 'fullAddress'){

    }
    if(data.type === 'date'){

        CS.addToObserveList({
            callback: function(){
                datepicker(inp[0], CS.dpOptions)
            },
            element: inp[0],
        })
    }
    if(data.type === 'files'){
        CS.initFileManagerBox(out)
    }else{

        out.append(label).append(inp)
    }

    if(data.inputID)
        inp.attr('id', data.inputID)

    return out
}
export function createInputFromColumn(obj, override = []){
    let out = $('<span>')
        .html(obj.fullName)
        .addClass('badge bg-dark m-1 ')

    let inp = $('<input>')
        .attr('value', obj.value)
        .addClass('p-1')

    if(obj.subType?.includes('listdb:') || obj.subType?.includes('list:') || obj.subType2?.includes('listdb') || obj.subType2?.includes('list')){
        // inp.attr('type', 'hidden')
        let sel = $('<select>').attr('name', obj.shortName)
        sel.append($('<option>').attr('data-placeholder', 'true'))
        // if(obj.subType?.includes('list:') || obj.subType2?.includes('list')) {
        //     obj.options = obj.subType.substring(5).split(',')
        //     for(var x in obj.options){
        //         let item = obj.options[x]
        //         let opt = $('<option>')
        //             .val(item)
        //             .html(item)
        //         if(item === obj.clean)
        //             opt.attr('selected', 'selected')
        //
        //         sel.append(opt)
        //     }
        //
        // }else{
            for(var x in obj.options){
                let item = obj.options[x]
                console.log('??', obj.options, x, item)
                let opt = $('<option>')
                    .val(item.UID ?? item)
                    .html(item.Name ?? item)
                if(parseInt(item.UID) === parseInt(obj.clean))
                    opt.attr('selected', 'selected')
                else if(item === obj.clean)
                    opt.attr('selected', 'selected')
                sel.append(opt)
            }

        // }

        sel
            // .addClass('d-block text-center form-control form-control-sm')
            .addClass(obj.class)

        if(obj.width>0)
            sel.width(obj.width)

        CS.startTrackingChanges(sel);

        CS.addToObserveList({
            callback: function(){
                sel.trigger('change')
                // let x = $('<div>').addClass('ssContentDiv')
                // sel.parent().append(x)
                console.log('here?')
                $(sel).selectize()

                $('.taxable').each(function (){
                    CS.updateTaxes($(this))
                })


                // let z = startSelect(sel[0], {})
                // console.log('??',z)

                // startSelect({
                //     select: sel[0],
                //     settings: {...SlimSelectSettings, ...{
                //             contentLocation: x.get()[0],
                //             showSearch: obj.searchable??false,
                //         }},
                //     events: {...{
                //         beforeChange: (row) => {
                //             // console.log('change', opt, v, row)
                //             if(obj.shortName !== 'Vendor') return true;
                //             let v = row[0].value
                //             // inp.val(v)
                //             let opt = obj.options.find(x => x.UID === v)
                //             if(!opt) {console.log('no opt found', obj.options ,v); return; }
                //             let salesTax = opt.SalesTax??0
                //             if(override['vendorTaxRate'])
                //                 salesTax= parseFloat(override['vendorTaxRate'])
                //             $('.extraInputs .vendorTaxRate').val(salesTax+'%')
                //             $('.extraInputs .vendorAddress').val(opt.Address)
                //
                //             $('[name="cost"]').each(function (){
                //                 CS.updateTaxes($(this))
                //             })
                //             CS.updateSums()
                //
                //             return true
                //
                //         }
                //     }, ... SlimSelectEvents}
                // })
            },
            element: sel[0],
        })
        inp = sel;
        out.append(sel)

    }else{
        inp.attr('name', obj.shortName)
            .addClass('d-block text-center form-control form-control-sm')
            .addClass(obj.class)
            .width(obj.width>0 ? obj.width : 100)

        if(obj.value)
            inp.val(obj.value)
    }




    // if(obj.subType === 'date'){
    // if(obj.subType2.includes('date')){
    $(obj.subType2).each(function (){
        console.log('?',this === 'date',this,obj.subType, obj.subType2, inp)
        if(this === 'date')
        CS.addToObserveList({
            callback: function(){
                datepicker(inp[0], CS.dpOptions)
            },
            element: inp[0],
        })
    })

    CS.startTrackingChanges(inp);
    return out.append(inp)
}

export function createInputGroup(){

}

function startSelect(obj, opts){
    let x = $(obj).selectize(
        {...{
            onChange: function (o) { console.log('??>',o)}
            // events: {...opts.events, SelectEvents}
                // afterChange: (newVal) => {}

        }, ...opts
    })
    return x
}
