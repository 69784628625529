import currency from "currency.js";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

export function updateTotals(){
    let total = currency(0)
    $('.countSum').each(function(){
        total = total.add(this.value);
    });
    $('#total').html(total.format());
}

export function dateFormat(d){
    return 'xxx'
}

export function datePrototypes(){
    Date.prototype.addDays = function(days) {
        days = parseInt(days)
        var date = new Date(this.valueOf());
        console.log('term:', days,date.getDate(), date.getDate() + days)
        date.setDate(date.getDate() + days);

        return date;
    }

    Date.prototype.format = function() {
        return this.getDate()+'-'+this.getMonthName()+'-'+this.getFullYear()
    }
    Date.prototype.getMonthName = function() {
        return months[this.getMonth()]
    }
}

