let trackedObjects = [];

let numUnsavedChanges = 0;

export function initChangeTracker(){

}

export function startTrackingChanges(obj){
    obj = $(obj)
    obj.on('change', function (){
            if($(this).data('orig-value') != $(this).val()){
                obj.addClass('border border-warning')
                $('div[data-id="'+$(this).data('id')+'"]').addClass('border-warning')
                numUnsavedChanges++
            }else{
                numUnsavedChanges--
                obj.removeClass('border border-warning')
                $('div[data-id="'+$(this).data('id')+'"]').removeClass('border-warning')

            }

    })
        .data('orig-value', obj.val())

    obj.addClass('tracked')

    trackedObjects.push(obj)
}

export function getUnsavedChanges(){
    return numUnsavedChanges
}
export function clearTrackedChanges(){
    numUnsavedChanges = []
}

export function resetFormInputs(type = 'success'){
    $(trackedObjects).each(function (){
        if($(this).data('orig-value') != $(this).val()) {
            $(this).removeClass('border-warning')
            $(this).addClass('border-success')
            setTimeout(() => {
                // $(this).removeClass('border-success')
            },1000)
            // $(this).animate({borderWidth: '+=2px'},500, function (){
            //     $(this).animate({borderWidth: '0px'}, 500)
            // })
        }

    })
}

export function initValidations(){
    $('.required').each(function (){
        console.log('validations', this)
        $(this).on('change',  (i) => {
            console.log(i)
            if(i.currentTarget.value === '') {

                $(this).removeClass('border-success border')
                $('div[data-id="'+$(this).data('id')+'"]').removeClass('border-success border')
                $(this).addClass('border-danger border')
                $('div[data-id="'+$(this).data('id')+'"]').addClass('border-danger border')

                $(this).attr('data-valid', 0)

            }else {
                $(this).removeClass('border-danger border')
                $('div[data-id="'+$(this).data('id')+'"]').removeClass('border-danger border')
                $(this).attr('data-valid', 1)
                $(this).addClass('border-success border')
                $('div[data-id="'+$(this).data('id')+'"]').addClass('border-success border')

            }
        }).trigger('change')

    })
}

export function getInvalidFields(){
    return $('[data-valid="0"]')
}
