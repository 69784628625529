let notifs = [];
let timers = [];

function createAlertHTML(type){
    let out = $('<div>')
        .addClass("toast align-items-center border-0 text-white")
        .addClass("bg-"+ (type === 'confirm' ? 'warning' : type))
        .attr({
            'role': "alert",
            'aria-live': "assertive",
            'aria-atomic': "true",
        })
    let container = $('<div>')
                .addClass("d-flex")
    let body = $('<div>').addClass("toast-body")
    let dataContainer = $('<div>').addClass("dataContainer-"+type)

    let btn = $('<button>')
        .attr({
            'type': "button",
            'data-bs-dismiss':"toast",
            'aria-label': "Close"
        })
        .addClass('btn-close btn-close-white float-end')

    body.append(btn)
    body.append(dataContainer)
    container.append(body)
    out.append(container)
    $('.toast-container.notifs ').append(out)

    return out;
}

export function initNotifications(){
    // notifs['warning'] = new Toast(createAlert('warning'))
    // notifs['success'] = new Toast(createAlert('success'))
    // notifs['error'] = new Toast(createAlert('error'))
}

export function confirm(text, success = () => {} , fail = () => {}){
    let obj = getNotif('confirm')

    let dataContainer = $('.dataContainer-confirm').first()

    let yes = $('<span>')
        .html('<i class="fa-solid fa-check me-2"></i>Yes')
        .addClass('btn btn-success')
        .on('click', function () {
            success()
            obj.hide()

        })
    let no = $('<span>')
        .html('<i class="fa-solid fa-xmark me-2"></i>No')
        .addClass('btn btn-danger  float-end')
        .on('click',  function () {
            fail()
            obj.hide()

        })

    let txt = $('<div>').html(text)

    dataContainer
        .append(txt)
        .append($('<hr>'))
        .append(yes)
        .append(no)

    obj.show()
    return obj
}
export function notif(type, text,id){
    let obj = getNotif(type,id)
    let t = $('<div>').html(text)
    // if(!$('.dataContainer-'+type+':contains("'+text+'")'))
        $('.dataContainer-'+type).html(t)

    if(!obj.isShown())
        obj.show();

    return obj;
}

function getNotif(type,id){
    if(notifs[id]) return notifs[id]
    if(notifs[type]) return notifs[type]
    return spawnAlert(type)
}

function spawnAlert(type){
    let h = createAlertHTML(type)
    let n = new Toast(h, {
        autohide: type==='success'
    })
    $(h).on('hidden.bs.toast', function () {
        $('.dataContainer-'+type).html('')
    })
    notifs[type] = n
    return n
}

export function showInfo(text, opts) {
    return notif('warning', text)
    let obj = $('#alert-warning')[0]
    $('.toast-body', obj).html(text)
    new Toast(obj, opts).show()
}

export function showSuccess(text, opts) {
    return notif('success', text)
    let obj = $('#alert-success')[0]
    $('.toast-body', obj).html(text)
        new Toast(obj, opts).show()
}

export function showError(text, opts) {
    return notif('danger', text)
    let obj = $('#alert-danger')[0]
    $('.toast-body', obj).html(text)
    new Toast(obj, opts).show()
}

export function expiredSessionDialog() {
    showError('This page has expired; please refresh')
}


export function showErrors(errors) {
    var e = '';
    for(let i in errors) e+="<div>"+errors[i].toString()+"</div>"
    return e == '' ? null : showError(e)
}

export function showWarnings(errors) {
    var e = '';
    for(let i in errors) e+="<div>"+errors[i].toString()+"</div>"
    return e == '' ? null : showInfo(e)
}


//
// let modalObj
// let modalDialog
//
// let modalWidth = 600;
// // let modalHeight = 400;
//
// export function initModal(){
//     modalObj = $('#CSModal')[0];
//     $(modalObj).on('show.bs.modal', function (e) {
//         $('.modal-dialog', modalObj).css('width', modalWidth);
//         // $('.modal-dialog', modalObj).css('height', modalHeight);
//     });
//     modalDialog = new window.Modal(modalObj, {})
// }
//
// export function openModal(type, route, opts = []){
//     modalWidth = opts.width ? opts.width : 600;
//     modalDialog.show()
//     $('.modal-title', modalObj).html(type);
//     $('.modal-body', modalObj).html(renderSpinner())
//
//     // modalHeight = opts.height ? opts.height : 400;
//
//
//
//     let frame = $('<iframe>').attr('src', route).on('load', function (){
//         $('.modal-loader').remove()
//         $('.modal-body iframe').show()
//
//     }).hide();
//     $('.modal-body', modalObj).append(frame)
//
// }
