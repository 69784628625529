import defaultExport from 'load-google-maps-api';
// import * as loadGoogleMapsApi from 'load-google-maps-api';

let googleGeocoder = false
let googleMap = false
let googleMapData = false
let googleMapMarker = false
let googleAutocomplete = false
let googleMaps = false;
let _mapElement = false

let inputID = '#address'

let autocomplete, marker, infowindow, map;


class Map {


    static loadGoogleMapsApi() {
        console.log('api-key', import.meta.env.VITE_GOOGLE_API_KEY)
        return defaultExport({key: import.meta.env.VITE_GOOGLE_API_KEY, libraries: ['places']});
    }

    static getGoogleMapsApi() {
        return googleMaps
    }
    static getGoogleMapData() {
        return googleMapData
    }

    static setGoogleMapsApi(gmapi) {
        googleMaps = gmapi
// return
        googleGeocoder = new googleMaps.Geocoder();

        var inputss = document.querySelector('#address');
        googleAutocomplete = new googleMaps.places.Autocomplete(inputss);

    }


    static createMap(mapElement, center) {
        _mapElement = mapElement

        googleMap = new googleMaps.Map(mapElement, {
            center: center, zoom: 14, disableDefaultUI: true, mapTypeId: googleMaps.MapTypeId.ROADMAP
        });

        googleMapMarker = new googleMaps.Marker({
            map: googleMap
        });


    }

    static geocode(opts, callback, mapElement) {
        _mapElement = mapElement
        googleGeocoder.geocode(opts, callback || CS.Map.handleGeocodeResult)
    }

    static handleGeocodeResult(results, status) {
        console.log('handleGeocodeResult', results);
        let lat = results[0].geometry.location.lat();
        let lon = results[0].geometry.location.lng();

        CS.Map.createMap(_mapElement, {lat: lat, lng: lon})

        googleMap.fitBounds(results[0].geometry.viewport);

        googleMapMarker.setPosition(results[0].geometry.location);
        googleMapMarker.setVisible(true);

        googleMaps.event.addListener(googleAutocomplete, 'place_changed', CS.Map.handlePlaceChanged)

    }

    static handlePlaceChanged(a, b) {
        googleMapMarker.setVisible(false);


        var place = googleAutocomplete.getPlace();

        let valid = 0;
        $(place.address_components).each(function (a, b) {
            if (b.types[0] == 'route') valid++
            if (b.types[0] == 'street_number') valid++
        })

        if (valid < 2) {
            alert('Please enter full street address')
            $('#address')[0].value = ''
            return
        }
        googleMapData = place
        //
        // if (!place.geometry) {
        //     alert('error address');
        // }
        if (place.geometry.viewport) {
            googleMap.fitBounds(place.geometry.viewport);
        } else {
            alert('error');
        }

        googleMapMarker.setPosition(place.geometry.location);
        googleMapMarker.setVisible(true);


        // document.getElementById('latitude').value = place.geometry.location.lat();
        // document.getElementById('longitude').value = place.geometry.location.lng();
        // $('#address').val(place.formatted_address);
        $('#fullAddress').html(place.formatted_address);

    }
}

export {Map};
